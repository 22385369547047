.index-prev,
.index-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 34px;
    color: #fff;
    cursor: pointer;
    outline: none;
    z-index: 9;
    transition: all .3s;
    &:hover {
        color: $mainYellow;
    }
    @media screen and (max-width: 1600px) {
        font-size: 30px;
    }
    @media screen and (max-width: 1440px) {
        font-size: 26px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 22px;
    }
    @media screen and (max-width: 750px) {
        font-size: 18px;
    }
}

.index-banner {
    .swiper-slide {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (850 / 1920) * 100%;
        overflow: hidden;
    }
    .img-pc,
    .img-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .img-mobile {
        display: none;
    }
    .index-prev {
        left: calc((100% - 1460px) / 2 - 50px);
    }
    .index-next {
        right: calc((100% - 1460px) / 2 - 50px);
    }
    .index-banner-pagination {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 40px;
        text-align: center;
        font-size: 0;
        z-index: 9;
        .swiper-pagination-bullet {
            vertical-align: top;
            margin: 0 12px;
            width: 13px;
            height: 14px;
            opacity: 1;
            background: url(../images/pagination1.png) center center no-repeat;
            background-size: contain;
            cursor: pointer;
            &-active {
                background-image: url(../images/pagination2.png);
            }
        }
    }
    @media screen and (max-width: 1560px) {
        .index-prev {
            left: 20px;
        }
        .index-next {
            right: 20px;
        }
    }
    @media screen and (max-width: 1440px) {
        .index-prev {
            left: px2rem(15px);
        }
        .index-next {
            right: px2rem(15px);
        }
        .index-banner-pagination {
            bottom: 30px;
            .swiper-pagination-bullet {
                margin: 0 10px;
                width: 11px;
                height: 12px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .index-banner-pagination {
            bottom: 25px;
            .swiper-pagination-bullet {
                width: 9px;
                height: 10px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .index-banner-pagination {
            bottom: px2rem(50px);
            .swiper-pagination-bullet {
                margin: 0 px2rem(20px);
                width: px2rem(18px);
                height: px2rem(20px);
            }
        }
        .swiper-slide {
            padding-bottom: (980 / 750) * 100%;
        }
        .img-pc {
            display: none;
        }
        .img-mobile {
            display: block;
        }
    }
}

.index-title {
    margin-bottom: 40px;
    text-align: center;
    a {
        display: inline-flex;
        vertical-align: top;
        text-align: left;
        justify-content: center;
        align-items: flex-start;
    }
    .icon {
        margin: 10px 10px 0 0;
        width: 34px;
        height: auto;
    }
    .title {
        line-height: 1.4;
        font-size: 40px;
        color: #333;
    }
    p {
        line-height: 1.2;
        font-family: Arial;
        font-size: 16px;
        color: #999;
        text-transform: uppercase;
        letter-spacing: 1.6px;
    }
    @media screen and (max-width: 1440px) {
        margin-bottom: 35px;
        .icon {
            margin-top: 8px;
            width: 32px;
        }
        .title {
            font-size: 36px;
        }
        p {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 30px;
        .icon {
            width: 28px;
        }
        .title {
            font-size: 32px;
        }
        p {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1024px) {
        .icon {
            margin-top: 6px;
            width: 24px;
        }
        .title {
            font-size: 28px;
        }
        p {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 16px;
        .icon {
            margin: 5px 6px 0 0;
            width: 19px;
        }
        .title {
            font-size: 22px;
        }
        p {
            font-size: 10px;
            letter-spacing: .8px;
        }
    }
}

.index-more {
    margin: 0 auto;
    display: block;
    width: 198px;
    line-height: 42px;
    text-align: center;
    font-size: 14px;
    color: #888;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    transition: all .3s;
    &:hover {
        color: #fff;
        background-color: $mainYellow;
        border-color: $mainYellow;
    }
    @media screen and (max-width: 1200px) {
        width: 160px;
        line-height: 32px;
        font-size: 13px;
    }
    @media screen and (max-width: 750px) {
        width: 130px;
        line-height: 28px;
        font-size: 12px;
    }
}

.index-about {
    padding: 40px 40px 0;
    background-color: #fff;
    &-wp {
        padding: 230px 0 75px;
        background-size: cover;
        background-position: left center;
        background-repeat: no-repeat;
    }
    .index-title {
        margin-bottom: 305px;
        .title {
            color: #f8f7f7;
        }
    }
    &-box {
        display: flex;
        background-color: #fff;
    }
    &-recommend {
        width: (743 / 1460) * 100%;
        background: url(../images/bg5.png) left bottom no-repeat;
        a {
            display: flex;
            padding: 40px 70px 40px 80px;
            height: 100%;
            box-sizing: border-box;
            &:hover {
                h3 {
                    color: $mainYellow;
                }
            }
        }
        .title {
            margin-right: 26px;
            text-align: right;
        }
        h3 {
            line-height: 1.2;
            font-size: 30px;
            color: #333;
            transition: all .3s;
        }
        .en {
            margin: 6px 0 18px;
            line-height: 1.2;
            font-family: Arial;
            font-size: 20px;
            color: #8a8a8a;
            text-transform: uppercase;
        }
        .more {
            display: inline-block;
            vertical-align: top;
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            background-color: $mainYellow;
            border-radius: 50%;
        }
        .info {
            flex: 1;
            min-width: 0;
            margin-top: -2px;
            max-height: 32px * 3;
            line-height: 32px;
            font-size: 14px;
            color: #888;
            @include multiEllipsis(3);
        }
    }
    &-list {
        display: flex;
        flex: 1;
        min-width: 0;
        text-align: center;
        li {
            flex: 1;
            min-width: 0;
            border-left: 1px solid #e0e0e0;
            &:nth-child(odd) {
                background-color: #ededed;
            }
        }
        a {
            display: block;
            padding: 46px 20px 40px;
            height: 100%;
            box-sizing: border-box;
            transition: all .3s;
            &:hover {
                background-color: $mainYellow;
                .icon {
                    &-default {
                        display: none;
                    }
                    &-hover {
                        display: inline-block;
                    }
                }
                h3,
                p {
                    color: #fff;
                }
            }
        }
        .icon {
            margin-bottom: 8px;
            text-align: center;
            height: 42px;
            line-height: 42px;
            img {
                vertical-align: middle;
                max-height: 100%;
                max-width: 100%;
            }
            &-default {
                display: inline-block;
            }
            &-hover {
                display: none;
            }
        }
        h3 {
            line-height: 1.4;
            font-size: 24px;
            color: #333;
            transition: all .3s;
        }
        p {
            line-height: 1.2;
            font-family: Arial;
            font-size: 14px;
            color: #999;
            transition: all .3s;
        }
    }
    @media screen and (max-width: 1600px) {
        padding: px2rem(30px) px2rem(30px) 0;
        &-wp {
            padding-top: 200px;
        }
        .index-title {
            margin-bottom: 240px;
        }
    }
    @media screen and (max-width: 1440px) {
        &-wp {
            padding-top: 160px;
        }
        .index-title {
            margin-bottom: 200px;
        }
        &-recommend {
            a {
                padding: 35px 50px 35px 60px;
            }
            h3 {
                font-size: 26px;
            }
            .en {
                margin-bottom: 15px;
                font-size: 16px;
            }
        }
        &-list {
            a {
                padding: 40px 20px 36px;
            }
            .icon {
                height: 38px;
                line-height: 38px;
            }
            h3 {
                font-size: 22px;
            }
            p {
                font-size: 13px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-wp {
            padding-top: 120px;
        }
        .index-title {
            margin-bottom: 160px;
        }
        &-recommend {
            a {
                padding: 30px 40px 30px 50px;
            }
            h3 {
                font-size: 24px;
            }
            .en {
                font-size: 14px;
            }
            .more {
                width: 26px;
                height: 26px;
                line-height: 26px;
                font-size: 13px;
            }
            .info {
                max-height: 28px * 3;
                line-height: 28px;
                font-size: 13px;
            }
        }
        &-list {
            a {
                padding: 32px 10px 26px;
            }
            .icon {
                height: 35px;
                line-height: 35px;
            }
            h3 {
                font-size: 20px;
            }
            p {
                font-size: 12px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-wp {
            padding: px2rem(240px) 0 px2rem(85px);
        }
        .index-title {
            margin-bottom: px2rem(70px);
        }
        &-box {
            display: block;
        }
        &-recommend {
            width: 100%;
        }
        &-list {
            border-top: 1px solid #e0e0e0;
            &:first-child {
                border-left: none;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-recommend {
            background-size: 30% auto;
            a {
                padding: px2rem(45px) px2rem(30px) px2rem(50px) px2rem(40px);
            }
            .title {
                margin-right: px2rem(30px);
            }
            h3 {
                font-size: 18px;
            }
            .en {
                margin: 2px 0 8px;
                font-size: 12px;
            }
            .more {
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size: 10px;
            }
            .info {
                max-height: 24px * 3;
                line-height: 24px;
            }
        }
        &-list {
            a {
                padding: px2rem(40px) px2rem(10px) px2rem(30px);
            }
            .icon {
                margin-bottom: 6px;
                height: 25px;
                line-height: 25px;
            }
            h3 {
                font-size: 15px;
            }
            p {
                font-size: 9px;
            }
        }
    }
}

.index-service {
    padding: px2rem(70px) 0 95px;
    background: linear-gradient(180deg, #f5f5f5 62%, #fff 62%);
    overflow: hidden;
    &-swiper {
        position: relative;
        .index-prev,
        .index-next {
            color: #999;
            &:hover {
                color: $mainYellow;
            }
        }
        .index-prev {
            left: -40px;
        }
        .index-next {
            right: -40px;
        }
        .swiper-slide {
            width: 25%;
            a {
                position: relative;
                display: block;
                height: 0;
                padding-bottom: (650 / 360) * 100%;
                overflow: hidden;
                &:hover {
                    .mask {
                        top: 0;
                        &-title,
                        &-en,
                        &-p,
                        &-more {
                            opacity: 1;
                            transition-delay: .2s;
                        }
                    }
                    .info {
                        transform: translateY(200%);
                        transition-delay: .2s;
                    }
                }
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .info {
                position: absolute;
                left: 30px;
                right: 30px;
                bottom: 30px;
                z-index: 2;
                transition: all .3s;
            }
            h3 {
                line-height: 1.4;
                font-size: 24px;
                color: #fff;
            }
            p {
                line-height: 1.2;
                font-family: Arial;
                font-size: 16px;
                color: #d0d0d0;
            }
            .mask {
                position: absolute;
                top: -100%;
                left: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 30px 50px 50px;
                width: 100%;
                height: 100%;
                text-align: center;
                background: rgba(0, 0, 0, .7);
                box-sizing: border-box;
                z-index: 6;
                transition: all .3s;
                &-title {
                    margin-bottom: 8px;
                    line-height: 1.4;
                    font-size: 32px;
                    color: #fff;
                    opacity: 0;
                    transition: all .2s;
                }
                &-en {
                    font-family: Arial;
                    line-height: 1.2;
                    font-size: 12px;
                    color: #d0d0d0;
                    opacity: 0;
                    transition: all .2s;
                }
                &-p {
                    margin: 30px 0 45px;
                    height: 36px * 5;
                    line-height: 36px;
                    font-size: 16px;
                    color: #d0d0d0;
                    @include multiEllipsis(5);
                    opacity: 0;
                    transition: all .2s;
                }
                &-more {
                    margin: 0 auto;
                    width: 42px;
                    height: 42px;
                    line-height: 42px;
                    text-align: center;
                    font-size: 16px;
                    color: #b4b4b4;
                    border: 1px solid #b4b4b4;
                    border-radius: 50%;
                    opacity: 0;
                    transition: all .2s;
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        &-swiper {
            .index-prev {
                left: -35px;
            }
            .index-next {
                right: -35px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        &-swiper {
            .index-prev {
                left: -30px;
            }
            .index-next {
                right: -30px;
            }
            .swiper-slide {
                .info {
                    left: px2rem(25px);
                    right: px2rem(25px);
                    bottom: 25px;
                }
                h3 {
                    font-size: 22px;
                }
                p {
                    font-size: 14px;
                }
                .mask {
                    padding: px2rem(20px) px2rem(30px) px2rem(30px);
                    &-title {
                        font-size: 28px;
                    }
                    &-p {
                        margin: 24px 0 35px;
                        height: 32px * 5;
                        line-height: 32px;
                        font-size: 14px;
                    }
                    &-more {
                        width: 36px;
                        height: 36px;
                        line-height: 36px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: px2rem(85px);
        &-swiper {
            .swiper-slide {
                h3 {
                    font-size: 20px;
                }
                p {
                    font-size: 12px;
                }
                .mask {
                    &-title {
                        margin-bottom: 6px;
                        font-size: 24px;
                    }
                    &-en {
                        font-size: 11px;
                    }
                    &-p {
                        margin: 20px 0 30px;
                        height: 28px * 5;
                        line-height: 28px;
                        font-size: 13px;
                    }
                    &-more {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-swiper {
            .swiper-slide {
                width: (100% / 3);
            }
            .index-prev {
                left: -26px;
            }
            .index-next {
                right: -26px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-swiper {
            .index-prev {
                left: -16px;
            }
            .index-next {
                right: -16px;
            }
            .swiper-slide {
                width: 50%;
                .info {
                    bottom: px2rem(30px);
                }
                h3 {
                    font-size: 16px;
                }
                p {
                    font-size: 10px;
                }
                .mask {
                    &-title {
                        margin-bottom: 4px;
                        font-size: 20px;
                    }
                    &-en {
                        font-size: 10px;
                    }
                    &-p {
                        margin: 12px 0 20px;
                        height: 24px * 3;
                        line-height: 24px;
                        font-size: 12px;
                        -webkit-line-clamp: 3;
                    }
                    &-more {
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                        font-size: 10px;
                    }
                }
            }
        }
    }
}

.index-join {
    padding: 100px 0 90px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    .index-title {
        margin-bottom: 50px;
        .title {
            color: #f8f7f7;
        }
    }
    &-swiper {
        position: relative;
        margin-bottom: 70px;
        .index-prev {
            left: -40px;
        }
        .index-next {
            right: -40px;
        }
        .swiper-slide {
            width: 25%;
            a {
                display: block;
                padding: 60px 60px 55px;
                height: 100%;
                text-align: center;
                background-color: #fff;
                box-sizing: border-box;
                transition: all .3s;
                &:hover {
                    background-color: rgba(37, 32, 28, .8);
                    h3,
                    .p,
                    .more {
                        color: #f8f7f7;
                    }
                    .more {
                        border-color: #878483;
                    }
                }
            }
            .icon {
                height: 64px;
                line-height: 64px;
                img {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            h3 {
                margin: 36px 0 15px;
                line-height: 1.4;
                font-size: 22px;
                color: #333;
                transition: all .3s;
            }
            .p {
                height: 26px * 3;
                line-height: 26px;
                font-size: 14px;
                color: #999;
                @include multiEllipsis(3);
                transition: all .3s;
            }
            .more {
                display: inline-block;
                vertical-align: top;
                margin-top: 20px;
                width: 25px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                font-size: 12px;
                color: #666;
                border: 1px solid #b6b6b6;
                border-radius: 50%;
                transition: all .3s;
            }
        }
    }
    @media screen and (max-width: 1600px) {
        &-swiper {
            .index-prev {
                left: -35px;
            }
            .index-next {
                right: -35px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        &-swiper {
            .index-prev {
                left: -30px;
            }
            .index-next {
                right: -30px;
            }
            .swiper-slide {
                a {
                    padding: 50px 40px 45px;
                }
                .icon {
                    height: 56px;
                    line-height: 56px;
                }
                h3 {
                    margin: 30px 0 12px;
                    font-size: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: px2rem(80px) 0 px2rem(85px);
        .index-title {
            margin-bottom: 40px;
        }
        &-swiper {
            margin-bottom: px2rem(60px);
            .swiper-slide {
                a {
                    padding: 40px 30px 35px;
                }
                .icon {
                    height: 48px;
                    line-height: 48px;
                }
                h3 {
                    margin: 24px 0 8px;
                }
                .p {
                    height: 24px * 3;
                    line-height: 24px;
                    font-size: 13px;
                }
                .more {
                    margin-top: 16px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-swiper {
            .index-prev {
                left: -26px;
            }
            .index-next {
                right: -26px;
            }
            .swiper-slide {
                width: (100% / 3);
            }
        }
    }
    @media screen and (max-width: 750px) {
        .index-title {
            margin-bottom: 20px;
        }
        &-swiper {
            .index-prev {
                left: -16px;
            }
            .index-next {
                right: -16px;
            }
            .swiper-slide {
                width: 50%;
                a {
                    padding: px2rem(60px) px2rem(30px) px2rem(55px);
                }
                .icon {
                    height: 38px;
                    line-height: 38px;
                }
                h3 {
                    margin: 14px 0 5px;
                    font-size: 16px;
                }
                .p {
                    height: 22px * 3;
                    line-height: 22px;
                    font-size: 12px;
                }
                .more {
                    margin-top: 10px;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 10px;
                }
            }
        }
    }
}

.index-news {
    padding: px2rem(75px) 0 px2rem(80px);
    background: url(../images/bg6.png) right bottom no-repeat;
    background-size: (678 / 1920) * 100% auto;
    &-wp {
        display: flex;
        margin-bottom: px2rem(40px);
    }
    &-recommend {
        flex: 1;
        min-width: 0;
        margin-right: 30px;
        background-color: #fff;
        a {
            display: block;
            padding: 40px 40px 30px;
            height: 100%;
            box-sizing: border-box;
            &:hover {
                h3 {
                    color: $mainYellow;
                }
            }
        }
        .img {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: (375 / 630) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .time {
            position: absolute;
            left: 0;
            bottom: 0;
            text-align: center;
            line-height: 1.3;
            padding: 12px;
            font-family: "Microsoft YaHei";
            color: #fff;
            background-color: $mainYellow;
            z-index: 2;
            .year {
                font-size: 20px;
                font-weight: bold;
            }
            .day {
                font-size: 16px;
            }
        }
        h3 {
            margin: 20px 0 8px;
            line-height: 1.4;
            font-size: 18px;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            transition: all .3s;
        }
        .p {
            max-height: 26px * 2;
            line-height: 26px;
            font-size: 13px;
            color: #777;
            @include multiEllipsis(2);
        }
        .more {
            margin-top: 14px;
            line-height: 1.2;
            font-size: 12px;
            color: #afafaf;
        }
    }
    &-list {
        width: (830 / 1460) * 100%;
        li {
            background-color: #fff;
            & + li {
                border-top: 1px dashed #efeeee;
            }
        }
        a {
            display: flex;
            padding: 30px 50px 30px 30px;
            &:hover {
                h3 {
                    color: $mainYellow;
                }
            }
        }
        .img {
            width: 340px;
            &-wp {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: (375 / 630) * 100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .info {
            flex: 1;
            min-width: 0;
            padding: 12px 0 0 30px;
        }
        h3 {
            line-height: 1.4;
            font-size: 18px;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            transition: all .3s;
        }
        .time {
            margin: 2px 0 18px;
            line-height: 1.2;
            font-size: 12px;
            color: #999;
        }
        .p {
            height: 26px * 3;
            line-height: 26px;
            font-size: 13px;
            color: #777;
            @include multiEllipsis(3);
        }
        .more {
            margin-top: 18px;
            line-height: 1.2;
            font-size: 12px;
            color: #afafaf;
        }
    }
    @media screen and (max-width: 1440px) {
        &-recommend {
            a {
                padding: px2rem(30px);
            }
            .time {
                padding: 10px;
                .year {
                    font-size: 18px;
                }
                .day {
                    font-size: 14px;
                }
            }
            h3 {
                font-size: 17px;
            }
        }
        &-list {
            a {
                padding-right: 40px;
            }
            .img {
                width: 280px;
            }
            .info {
                padding-top: 6px;
            }
            h3 {
                font-size: 17px;
            }
            .time {
                margin-bottom: 15px;
            }
            .more {
                margin-top: 12px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-recommend {
            .time {
                padding: 8px 7px;
                .year {
                    font-size: 16px;
                }
                .day {
                    font-size: 12px;
                }
            }
            h3 {
                font-size: 16px;
            }
        }
        &-list {
            a {
                padding-right: 30px;
            }
            .img {
                width: 240px;
            }
            h3 {
                font-size: 16px;
            }
            .p {
                max-height: 26px * 2;
                -webkit-line-clamp: 2;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        background-image: url(../images/bg7.png);
        background-size: 356px auto;
        &-wp {
            display: block;
        }
        &-recommend {
            margin: 0 0 px2rem(30px);
            .img {
                &:after {
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                }
            }
        }
        &-list {
            width: 100%;
            .img-wp {
                &:after {
                    width: 32px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        background-size: (356 / 750) * 100% auto;
        &-recommend {
            .img {
                &:after {
                    width: 26px;
                    height: 26px;
                    line-height: 26px;
                    font-size: 12px;
                }
            }
            .time {
                padding: 7px;
                .year {
                    font-size: 15px;
                }
            }
            h3 {
                margin: 14px 0 7px;
            }
            .p {
                max-height: 44px;
                line-height: 22px;
                font-size: 12px;
            }
            .more {
                margin-top: 8px;
                font-size: 10px;
            }
        }
        &-list {
            a {
                padding: px2rem(40px) px2rem(30px);
            }
            .img {
                display: none;
            }
            .info {
                padding: 0;
            }
            .time {
                margin-bottom: 10px;
            }
            .p {
                max-height: 66px;
                line-height: 22px;
                -webkit-line-clamp: 3;
                font-size: 12px;
            }
            .more {
                margin-top: 8px;
                font-size: 10px;
            }
        }
    }
}