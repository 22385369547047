.history-box {
    padding: 65px 200px 90px;
    background: #fff url(../images/bg1.jpg) bottom center no-repeat;
    background-size: 100% auto;
    @media screen and (max-width: 1440px) {
        padding: 65px 120px 80px;
    }
    @media screen and (max-width: 1200px) {
        padding: px2rem(65px) 80px px2rem(70px);
    }
    @media screen and (max-width: 1024px) {
        padding-left: px2rem(30px);
        padding-right: px2rem(30px);
    }
}

.history-title {
    text-align: center;
    line-height: 1.4;
    font-size: 46px;
    color: #333;
    text-transform: uppercase;
    .red {
        color: #e70012;
    }
    @media screen and (max-width: 1440px) {
        font-size: 40px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 36px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 32px;
    }
    @media screen and (max-width: 750px) {
        font-size: 22px;
    }
}

.history-content {
    margin-top: 5px;
    text-align: center;
    @include articleReset(20px, 30px);
    color: #666;
    p {
        margin-bottom: 0;
        font-weight: 300;
    }
    @media screen and (max-width: 1440px) {
        line-height: 28px;
        font-size: 18px;
    }
    @media screen and (max-width: 1200px) {
        line-height: 26px;
        font-size: 16px;
    }
    @media screen and (max-width: 1024px) {
        line-height: 24px;
        font-size: 14px;
    }
    @media screen and (max-width: 750px) {
        line-height: 22px;
        font-size: 13px;
    }
}

.history-list {
    position: relative;
    margin-top: 45px;
    li {
        position: relative;
        display: flex;
        align-items: center;
        padding: 30px 0;
        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            height: 50%;
            width: 3px;
            background-color: #efe5d7;
        }
        &:before {
            top: 0;
        }
        &:after {
            bottom: 0;
        }
        &:first-child {
            &:before {
                display: none;
            }
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
    .year {
        position: relative;
        padding: 0 20px 0 70px;
        font-size: 32px;
        color: #333;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 64px;
            border-bottom: 1px dashed #999;
        }
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: -9px;
            margin-top: -10px;
            padding: 5px;
            width: 9px;
            height: 9px;
            border: 1px dashed #d6000f;
            background-color: #d6000f;
            background-clip: content-box;
            border-radius: 50%;
            z-index: 6;
        }
    }
    .box {
        flex: 1;
        min-width: 0;
        display: flex;
        align-items: center;
        padding: 30px 40px 30px 30px;
        background-color: #fff;
        box-shadow: 0 0 px2rem(30px) rgba(0, 0, 0, .1);
    }
    .img {
        margin-right: 30px;
        width: (320 / 823) * 100%;
        &-wp {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: (190 / 320) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .info {
        flex: 1;
        min-width: 0;
        line-height: 30px;
        font-size: 18px;
        color: #000;
    }
    @media screen and (max-width: 1440px) {
        margin-top: 35px;
        .year {
            padding-left: 60px;
            font-size: 28px;
            &:after {
                width: 54px;
            }
        }
        .info {
            line-height: 28px;
            font-size: 16px;
        }
    }
    @media screen and (max-width: 1200px) {
        li {
            padding: 20px 0;
        }
        .year {
            font-size: 26px;
        }
        .info {
            line-height: 26px;
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-top: 25px;
        li {
            padding: 15px 0;
        }
        .year {
            padding-left: 50px;
            font-size: 24px;
            &:after {
                width: 46px;
            }
        }
        .box {
            padding: 20px 30px 20px 20px;
        }
        .img {
            margin-right: 20px;
        }
        .info {
            line-height: 24px;
            font-size: 13px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-top: 20px;
        li {
            display: block;
            padding: px2rem(20px) 0;
            &:after {
                top: calc(.1rem + 15px);
                height: 100%;
                width: 2px;
            }
            &:before {
                display: none;
            }
        }
        .year {
            padding: 0 0 0 px2rem(65px);
            font-size: 20px;
            &:before {
                left: -8px;
                margin-top: -9px;
                padding: 4px;
                width: 8px;
                height: 8px;
            }
            &:after {
                width: px2rem(60px);
            }
        }
        .box {
            display: block;
            margin: 10px 0 0 px2rem(60px);
            padding: px2rem(30px) px2rem(30px) px2rem(40px);
        }
        .img {
            margin: 0 0 14px;
            width: 100%;
        }
    }
}

.news-recommend {
    background-color: #fff;
    .swiper-slide {
        a {
            display: flex;
            padding: 55px 80px 55px 40px;
            height: 100%;
            background-color: #fff;
            box-sizing: border-box;
            &:hover {
                h3 {
                    color: $mainYellow;
                }
            }
        }
        .img {
            width: (630 / 1340) * 100%;
            &-wp {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: (375 / 630) * 100%;
                overflow: hidden;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .info {
            flex: 1;
            min-width: 0;
            padding: 35px 0 0 60px;
        }
        h3 {
            margin-bottom: 30px;
            max-height: 88px;
            line-height: 44px;
            font-size: 28px;
            color: #000;
            @include multiEllipsis(2);
            transition: all .3s;
        }
        .p {
            max-height: 90px;
            line-height: 30px;
            font-size: 16px;
            color: #666;
            @include multiEllipsis(3);
        }
        .time {
            margin-top: 25px;
            font-size: 14px;
            color: #999;
        }
    }
    .news-recommend-pagination {
        position: absolute;
        bottom: 75px;
        left: 40px;
        right: 80px;
        width: auto;
        font-size: 0;
        box-sizing: border-box;
        z-index: 9;
        &-wp {
            padding-left: calc((630 / 1340) * 100% + 60px);
        }
        .swiper-pagination-bullet {
            vertical-align: top;
            margin: 0 15px 0 0;
            width: 12px;
            height: 12px;
            opacity: 1;
            outline: none;
            background-color: #7d7d7d;
            cursor: pointer;
            transition: all .3s;
            &-active {
                background-color: $mainYellow;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .swiper-slide {
            a {
                padding: 40px 60px 40px 40px;
            }
            .info {
                padding: 24px 0 0 50px;
            }
            h3 {
                margin-bottom: 24px;
                max-height: 76px;
                line-height: 38px;
                font-size: 24px;
            }
            .p {
                font-size: 15px;
            }
            .time {
                margin-top: 20px;
                font-size: 13px;
            }
        }
        .news-recommend-pagination {
            bottom: 54px;
            right: 60px;
            &-wp {
                padding-left: calc((630 / 1340) * 100% + 50px);
            }
            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .swiper-slide {
            a {
                padding: px2rem(30px) 40px 30px px2rem(30px);
            }
            .info {
                padding: 12px 0 0 40px;
            }
            h3 {
                margin-bottom: 20px;
                max-height: 68px;
                line-height: 34px;
                font-size: 22px;
            }
            .p {
                max-height: 28px * 3;
                line-height: 28px;
                font-size: 14px;
            }
            .time {
                margin-top: 15px;
                font-size: 12px;
            }
        }
        .news-recommend-pagination {
            bottom: 35px;
            left: 30px;
            right: 40px;
            &-wp {
                padding-left: calc((630 / 1340) * 100% + 40px);
            }
            .swiper-pagination-bullet {
                margin-right: 12px;
                width: 8px;
                height: 8px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .swiper-slide {
            a {
                padding-right: px2rem(30px);
            }
            .info {
                padding: 2px 0 0 30px;
            }
            h3 {
                margin-bottom: 12px;
                max-height: 60px;
                line-height: 30px;
                font-size: 20px;
            }
            .p {
                max-height: 28px * 2;
                font-size: 13px;
                -webkit-line-clamp: 2;
            }
            .time {
                margin-top: 12px;
            }
        }
        .news-recommend-pagination {
            bottom: 30px;
            right: 30px;
            &-wp {
                padding-left: calc((630 / 1340) * 100% + 30px);
            }
        }
    }
    @media screen and (max-width: 750px) {
        .swiper-slide {
            a {
                display: block;
                padding-bottom: 40px;
            }
            .img {
                width: 100%;
            }
            .info {
                padding: 14px 0 0;
            }
            h3 {
                display: block;
                margin-bottom: 8px;
                max-height: 16px * 1.5;
                line-height: 1.5;
                font-size: 16px;
                white-space: nowrap;
            }
            .p {
                max-height: 24px * 2;
                line-height: 24px;
            }
            .time {
                margin-top: 8px;
            }
        }
        .news-recommend-pagination {
            bottom: 20px;
            right: 0;
            left: 0;
            text-align: center;
            &-wp {
                padding-left: 0;
            }
            .swiper-pagination-bullet {
                margin: 0 5px;
            }
        }
    }
}

.news-list {
    display: flex;
    flex-wrap: wrap;
    margin: px2rem(35px) px2rem(-15px) px2rem(15px);
    li {
        width: (100% / 3);
        padding: 0 px2rem(15px) px2rem(30px);
        box-sizing: border-box;
    }
    a {
        display: block;
        padding: 30px 30px 36px;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;
        transition: all .3s;
        &:hover {
            transform: translateY(-4px);
            box-shadow: 0 px2rem(5px) px2rem(16px) rgba(0, 0, 0, .15);
            h3 {
                color: $mainYellow;
            }
            .more {
                color: #fff;
                background-color: $mainYellow;
                border-color: $mainYellow;
            }
        }
    }
    .img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (375 / 630) * 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    h3 {
        margin: 18px 0 14px;
        line-height: 1.6;
        font-size: 18px;
        color: #000;
        overflow: hidden;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        transition: all .3s;
    }
    .p {
        max-height: 60px;
        line-height: 30px;
        font-size: 14px;
        color: #888;
        @include multiEllipsis(2);
    }
    .bottom {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }
    .time {
        flex: 1;
        min-width: 0;
        font-size: 14px;
        color: #999;
    }
    .more {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-family: iconfont;
        font-size: 12px;
        color: #7e7e7e;
        border: 1px solid #7e7e7e;
        border-radius: 50%;
        transition: all .3s;
        &:before {
            content: '\e623';
        }
    }
    @media screen and (max-width: 1440px) {
        h3 {
            margin-bottom: 12px;
            font-size: 16px;
        }
        .p {
            max-height: 56px;
            line-height: 28px;
            font-size: 13px;
        }
        .bottom {
            margin-top: 16px;
        }
        .time {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1200px) {
        a {
            padding: 20px 20px 24px;
        }
        h3 {
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            width: 50%;
        }
        a {
            padding: px2rem(30px) px2rem(30px) px2rem(35px);
        }
    }
    @media screen and (max-width: 750px) {
        h3 {
            margin: 10px 0 6px;
            max-height: 13px * 3.2;
            font-size: 13px;
            white-space: normal;
            @include multiEllipsis(2);
        }
        .p {
            display: none;
        }
        .bottom {
            margin-top: 0;
        }
        .time {
            font-size: 12px;
        }
        .more {
            display: none;
        }
    }
}

.video-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 9000;
    &-box {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 0;
        padding-bottom: (400 / 700) * 100%;
        overflow: hidden;
        .mejs__container,
        .mejs__poster,
        .mejs__overlay,
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
            overflow: hidden;
        }
        .mejs__poster,
        .video-box-img {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .mejs__overlay-button {
            width: 42px;
            height: 42px;
            background-position: 0 -66px;
            outline: none;
        }
        .mejs__overlay:hover > .mejs__overlay-button {
            background-position: -42px -66px;
        }
        .mejs__overlay-loading {
            width: 42px;
            height: 42px;
        }
        .mejs__overlay-loading-bg-img {
            width: 42px;
            height: 42px;
            background-position: -84px -66px;
        }
    }
    &-close {
        position: absolute;
        top: 0;
        right: 0;
        font-family: 'iconfont';
        width: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        background-color: $mainYellow;
        cursor: pointer;
        transition: all .3s;
        z-index: 9;
        &:before {
            content: '\e642';
        }
        &:hover {
            opacity: .8;
        }
    }
}

.pro-introduce {
    position: relative;
    padding: 75px 0;
    background-color: #fff;
    z-index: 2;
    &-article {
        @include articleReset(16px, 32px);
        color: #666;
        p {
            margin-bottom: 20px;
        }
        .pro-brand {
            margin-bottom: 16px;
        }
    }
    &-box {
        display: flex;
        align-items: center;
        .box-iframe {
            width: (700 / 1460) * 100%;
            iframe {
                display: block;
                width: 100%;
                height: 400px;
            }
        }
        .box-info {
            flex: 1;
            min-width: 0;
            margin: 0 0 -20px 50px;
        }
    }
    @media screen and (max-width: 1440px) {
        padding: 70px 0;
        &-article {
            line-height: 28px;
            font-size: 14px;
        }
        &-box {
            .box-iframe {
                iframe {
                    height: 360px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: px2rem(65px) 0;
        &-article {
            .pro-brand {
                img {
                    max-height: 45px;
                }
            }
        }
        &-box {
            .box-iframe {
                iframe {
                    height: 320px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-box {
            display: block;
            .box-iframe {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: (400 / 700) * 100%;
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .box-info {
                margin: 40px 0 -20px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-article {
            line-height: 28px;
            font-size: 13px;
            p {
                margin-bottom: 15px;
            }
            .pro-brand {
                margin-bottom: 12px;
                img {
                    max-height: 36px;
                }
            }
        }
        &-box {
            .box-info {
                margin: 20px 0 -15px;
            }
        }
    }
}

.pro-classify {
    position: relative;
    background-color: #f9f9f9;
    border-top: 1px solid #efeeee;
    box-shadow: 0 px2rem(10px) px2rem(30px) rgba(0, 0, 0, .12);
    &-list {
        li {
            display: flex;
            padding: 20px 0;
            line-height: 24px;
            & + li {
                border-top: 1px dashed #ddd;
            }
        }
        .name {
            font-weight: bold;
            font-size: 16px;
            color: #333;
            &-icon {
                display: inline-block;
                vertical-align: middle;
                margin-right: 4px;
                width: 18px;
                height: auto;
            }
        }
        .list {
            flex: 1;
            min-width: 0;
            margin-bottom: -10px;
            font-size: 0;
            a {
                display: inline-block;
                vertical-align: top;
                margin: 0 0 10px 42px;
                font-size: 15px;
                color: #777;
                transition: all .3s;
                &:hover,
                &.on {
                    color: $mainYellow;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        &-list {
            li {
                line-height: 22px;
            }
            .name {
                font-size: 15px;
                &-icon {
                    width: 16px;
                }
            }
            .list {
                a {
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-list {
            li {
                padding: 15px 0;
                line-height: 20px;
            }
            .name {
                font-size: 14px;
                &-icon {
                    margin-right: 2px;
                    width: 13px;
                }
            }
            .list {
                a {
                    margin: 0 0 8px 24px;
                    font-size: 13px;
                }
            }
        }
    }
}

.pro-page {
    padding: px2rem(50px) 0 90px;
    background: #efeeee url(../images/bg1.png) bottom right no-repeat;
    background-size: (507 / 1920) * 100%, auto;
    @media screen and (max-width: 1024px) {
        padding-bottom: px2rem(80px);
        background-size: (400 / 1024) * 100%, auto;
    }
    @media screen and (max-width: 750px) {
        background-image: url(../images/bg2.png);
        background-size: (300 / 750) * 100%, auto;
    }
}

.pro-series {
    display: flex;
    margin-bottom: px2rem(40px);
    padding: 35px 35px 35px 90px;
    background: #fff url(../images/bg4.png) bottom left no-repeat;
    background-size: 210px auto;
    box-shadow: 0 0 px2rem(20px) rgba(0, 0, 0, .1);
    &-title {
        padding-top: 52px;
        text-align: right;
        h2 {
            line-height: 1.4;
            font-size: 28px;
            color: #000;
        }
        p {
            line-height: 1.2;
            font-size: 16px;
            color: #999;
        }
        &:after {
            content: '';
            display: inline-block;
            vertical-align: top;
            margin-top: 20px;
            width: 50px;
            height: 4px;
            background-color: $mainYellow;
        }
    }
    &-article {
        flex: 1;
        min-width: 0;
        padding: 50px 90px 0 60px;
        @include articleReset(16px, 32px);
        color: #666;
        p {
            margin-bottom: 20px;
        }
    }
    &-img {
        position: relative;
        width: (480 / 1335) * 100%;
        height: 0;
        padding-bottom: (250 / 480) * (480 / 1335) * 100%;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    @media screen and (max-width: 1440px) {
        padding: 30px 30px 30px 80px;
        background-size: 180px auto;
        &-title {
            padding-top: 42px;
            h2 {
                font-size: 26px;
            }
            p {
                font-size: 15px;
            }
            &:after {
                height: 3px;
            }
        }
        &-article {
            padding: 40px 80px 0 50px;
            line-height: 30px;
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1200px) {
        padding-left: 60px;
        &-title {
            padding-top: 22px;
            h2 {
                font-size: 24px;
            }
            p {
                font-size: 14px;
            }
        }
        &-article {
            padding: 20px 60px 0 40px;
        }
    }
    @media screen and (max-width: 1024px) {
        display: block;
        padding: px2rem(50px) px2rem(30px);
        &-title {
            text-align: center;
            padding-top: 0;
        }
        &-article {
            margin: 20px 0;
            padding: 0;
            text-align: center;
        }
        &-img {
            width: 100%;
            padding-bottom: (250 / 480) * 100%;
        }
    }
    @media screen and (max-width: 750px) {
        background-size: 30% auto;
        &-title {
            h2 {
                font-size: 22px;
            }
            p {
                font-size: 12px;
            }
            &:after {
                margin-top: 12px;
                width: 35px;
                height: 2px;
            }
        }
        &-article {
            margin: 10px 0 16px;
            line-height: 28px;
            font-size: 13px;
            p {
                margin-bottom: 15px;
            }
        }
    }
}

.pro-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 px2rem(-15px);
    li {
        padding: 0 px2rem(15px) px2rem(30px);
        width: (100% / 3);
        box-sizing: border-box;
    }
    a {
        display: block;
        padding: px2rem(30px);
        text-align: center;
        background-color: #fff;
        transition: all .3s;
        &:hover {
            transform: translateY(-4px);
            box-shadow: 0 px2rem(5px) px2rem(16px) rgba(0, 0, 0, .15);
            .img {
                &:before {
                    opacity: 1;
                    transition-delay: 0s;
                }
                &:after {
                    top: 50%;
                    transition-delay: .2s;
                }
            }
            h3 {
                color: $mainYellow;
            }
        }
    }
    .img {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (260 / 400) * 100%;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, .5);
            opacity: 0;
            transition: all .3s .2s;
            z-index: 2;
        }
        &:after {
            content: '\e610';
            position: absolute;
            top: 120%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 38px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            font-family: iconfont;
            font-size: 16px;
            color: #fff;
            border: 1px solid #fff;
            border-radius: 50%;
            transition: all .2s;
            z-index: 8;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    h3 {
        margin-top: 26px;
        line-height: 1.4;
        font-size: 18px;
        color: #000;
        overflow: hidden;
        white-space: nowrap;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        transition: all .3s;
    }
    @media screen and (max-width: 1440px) {
        h3 {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 1200px) {
        h3 {
            font-size: 15px;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            width: 50%;
        }
        .img {
            &:after {
                width: 32px;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: px2rem(10px);
        .img {
            &:after {
                width: 26px;
                height: 26px;
                line-height: 26px;
                font-size: 12px;
            }
        }
        h3 {
            margin-top: px2rem(26px);
            font-size: 13px;
        }
    }
}

.case-page {
    padding: px2rem(65px) 0;
    background: #efeeee url(../images/bg1.png) bottom right no-repeat;
    background-size: (507 / 1920) * 100%, auto;
    .case-title {
        text-align: center;
    }
    @media screen and (max-width: 1024px) {
        background-size: (400 / 1024) * 100%, auto;
    }
    @media screen and (max-width: 750px) {
        background-image: url(../images/bg2.png);
        background-size: (300 / 750) * 100%, auto;
    }
}

.case-hd {
    margin-bottom: px2rem(40px);
    text-align: center;
    &-wp {
        display: inline-block;
        vertical-align: top;
        max-width: 100%;
        padding: 0 45px;
        line-height: 50px;
        height: 50px;
        font-size: 0;
        white-space: nowrap;
        background-color: #fff;
        border-radius: 50px;
        box-sizing: border-box;
        &.no-init {
            .swiper-wrapper {
                display: block;
                width: 100%;
            }
        }
        .swiper-wrapper {
            font-size: 0;
        }
        .swiper-slide {
            display: inline-block;
            vertical-align: top;
            width: auto;
            a {
                display: block;
                margin: 0 25px;
                font-size: 15px;
                color: #777;
                transition: all .3s;
            }
            &.on a,
            a:hover {
                color: $mainYellow;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-wp {
            padding: 0 35px;
            line-height: 45px;
            height: 45px;
            .swiper-slide {
                a {
                    margin: 0 20px;
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-wp {
            padding: 0 px2rem(20px);
            line-height: 40px;
            height: 40px;
            .swiper-slide {
                a {
                    margin: 0 12px;
                    font-size: 13px;
                }
            }
        }
    }
}

.case-bd {
    position: relative;
    .index-prev,
    .index-next {
        color: #999;
        &:hover {
            color: $mainYellow;
        }
    }
    .index-prev {
        left: -40px;
    }
    .index-next {
        right: -40px;
    }
    .pro-list {
        margin: 0;
        li {
            padding: 0;
        }
    }
    @media screen and (max-width: 1600px) {
        .index-prev {
            left: -35px;
        }
        .index-next {
            right: -35px;
        }
    }
    @media screen and (max-width: 1440px) {
        .index-prev {
            left: -30px;
        }
        .index-next {
            right: -30px;
        }
    }
    @media screen and (max-width: 1024px) {
        .index-prev {
            left: -26px;
        }
        .index-next {
            right: -26px;
        }
    }
    @media screen and (max-width: 750px) {
        .index-prev {
            left: -16px;
        }
        .index-next {
            right: -16px;
        }
    }
}

.store-main {
    padding-top: 20px;
    background: #efeeee url(../images/bg1.png) bottom right no-repeat;
    background-size: (507 / 1920) * 100%, auto;
    &-top {
        display: flex;
        width: 1100px;
        margin: 0 auto;
        max-width: 100%;
    }
    @media screen and (max-width: 1024px) {
        background-size: (400 / 1024) * 100%, auto;
        &-top {
            padding-top: px2rem(80px);
            display: block;
        }
    }
    @media screen and (max-width: 750px) {
        padding-bottom: px2rem(80px);
        background-image: url(../images/bg2.png);
        background-size: (300 / 750) * 100%, auto;
    }
}

.store-info {
    flex: 1;
    min-width: 0;
    padding-top: 100px;
    h3 {
        line-height: 36px;
        font-size: 28px;
        color: #010101;
        img {
            display: inline-block;
            vertical-align: middle;
            margin: -3px 5px 0 0;
            width: 26px;
        }
    }
    .en {
        margin: 5px 0 10px;
        line-height: 1;
        font-family: 'DINCond-Regular';
        font-size: 50px;
        color: #333;
        text-transform: uppercase;
    }
    p {
        line-height: 1.6;
        font-size: 16px;
        color: #929292;
    }
    @media screen and (max-width: 1200px) {
        h3 {
            line-height: 32px;
            font-size: 26px;
            img {
                width: 24px;
            }
        }
        .en {
            font-size: 46px;
        }
        p {
            font-size: 15px;
        }
    }
    @media screen and (max-width: 1024px) {
        padding-top: 0;
        text-align: center;
    }
    @media screen and (max-width: 750px) {
        h3 {
            line-height: 28px;
            font-size: 22px;
            img {
                width: 20px;
            }
        }
        .en {
            margin: 3px 0 6px;
            font-size: 36px;
        }
        p {
            font-size: 14px;
        }
    }
}

.map-box {
    position: relative;
    width: 626px;
    height: 472px;
    background: url(../images/map.png) center no-repeat;
    > * {
        display: block;
        position: absolute;
        z-index: 1;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .ah {
        width: 52px;
        height: 63px;
        top: 239px;
        left: 408px;
        &:hover,
        &.on {
            background-image: url(../images/maps/ah.gif);
        }
    }
    .bj {
        width: 17px;
        height: 18px;
        top: 166px;
        left: 407px;
        &:hover,
        &.on {
            background-image: url(../images/maps/bj.gif);
        }
    }
    .chq {
        width: 51px;
        height: 47px;
        top: 275px;
        left: 310px;
        &:hover,
        &.on {
            background-image: url(../images/maps/chq.gif);
        }
    }
    .gd {
        width: 81px;
        height: 63px;
        top: 352px;
        left: 363px;
        &:hover,
        &.on {
            background-image: url(../images/maps/gd.gif);
        }
    }
    .gs {
        width: 148px;
        height: 123px;
        top: 148px;
        left: 199px;
        &:hover,
        &.on {
            background-image: url(../images/maps/gs.gif);
        }
    }
    .gx {
        width: 80px;
        height: 59px;
        top: 344px;
        left: 305px;
        &:hover,
        &.on {
            background-image: url(../images/maps/gx.gif);
        }
    }
    .gz {
        width: 62px;
        height: 52px;
        top: 312px;
        left: 295px;
        &:hover,
        &.on {
            background-image: url(../images/maps/gz.gif);
        }
    }
    .hainan {
        width: 25px;
        height: 21px;
        top: 416px;
        left: 350px;
        &:hover,
        &.on {
            background-image: url(../images/maps/hainan.gif);
        }
    }
    .hb {
        width: 82px;
        height: 49px;
        top: 261px;
        left: 344px;
        &:hover,
        &.on {
            background-image: url(../images/maps/hb.gif);
        }
    }
    .heb {
        width: 58px;
        height: 81px;
        top: 146px;
        left: 388px;
        &:hover,
        &.on {
            background-image: url(../images/maps/heb.gif);
        }
    }
    .fj {
        width: 47px;
        height: 59px;
        top: 313px;
        left: 425px;
        &:hover,
        &.on {
            background-image: url(../images/maps/fj.gif);
        }
    }
    .hen {
        width: 62px;
        height: 56px;
        top: 223px;
        left: 362px;
        &:hover,
        &.on {
            background-image: url(../images/maps/hen.gif);
        }
    }
    .hlj {
        width: 116px;
        height: 106px;
        top: 13px;
        left: 431px;
        &:hover,
        &.on {
            background-image: url(../images/maps/hlj.gif);
        }
    }
    .hun {
        width: 56px;
        height: 65px;
        top: 298px;
        left: 350px;
        &:hover,
        &.on {
            background-image: url(../images/maps/hun.gif);
        }
    }
    .jl {
        width: 88px;
        height: 59px;
        top: 96px;
        left: 447px;
        &:hover,
        &.on {
            background-image: url(../images/maps/jl.gif);
        }
    }
    .js {
        width: 56px;
        height: 50px;
        top: 232px;
        left: 420px;
        &:hover,
        &.on {
            background-image: url(../images/maps/js.gif);
        }
    }
    .jx {
        width: 51px;
        height: 67px;
        top: 297px;
        left: 399px;
        &:hover,
        &.on {
            background-image: url(../images/maps/jx.gif);
        }
    }
    .ln {
        width: 61px;
        height: 53px;
        top: 129px;
        left: 434px;
        &:hover,
        &.on {
            background-image: url(../images/maps/ln.gif);
        }
    }
    .nmg {
        width: 226px;
        height: 196px;
        top: 17px;
        left: 245px;
        &:hover,
        &.on {
            background-image: url(../images/maps/nmg.gif);
        }
    }
    .nx {
        width: 34px;
        height: 47px;
        top: 192px;
        left: 301px;
        &:hover,
        &.on {
            background-image: url(../images/maps/nx.gif);
        }
    }
    .qh {
        width: 132px;
        height: 96px;
        top: 184px;
        left: 158px;
        &:hover,
        &.on {
            background-image: url(../images/maps/qh.gif);
        }
    }
    .sc {
        width: 111px;
        height: 96px;
        top: 250px;
        left: 233px;
        &:hover,
        &.on {
            background-image: url(../images/maps/sc.gif);
        }
    }
    .sd {
        width: 69px;
        height: 45px;
        top: 198px;
        left: 407px;
        &:hover,
        &.on {
            background-image: url(../images/maps/sd.gif);
        }
    }
    .shx {
        width: 55px;
        height: 93px;
        top: 188px;
        left: 314px;
        &:hover,
        &.on {
            background-image: url(../images/maps/shx.gif);
        }
    }
    .sx {
        width: 37px;
        height: 73px;
        top: 173px;
        left: 361px;
        &:hover,
        &.on {
            background-image: url(../images/maps/sx.gif);
        }
    }
    .tj {
        width: 15px;
        height: 20px;
        top: 175px;
        left: 427px;
        &:hover,
        &.on {
            background-image: url(../images/maps/tj.gif);
        }
    }
    .tw {
        width: 17px;
        height: 40px;
        top: 347px;
        left: 473px;
        &:hover,
        &.on {
            background-image: url(../images/maps/tw.gif);
        }
    }
    .xj {
        width: 208px;
        height: 156px;
        top: 63px;
        left: 25px;
        &:hover,
        &.on {
            background-image: url(../images/maps/xj.gif);
        }
    }
    .xz {
        width: 200px;
        height: 124px;
        top: 207px;
        left: 48px;
        &:hover,
        &.on {
            background-image: url(../images/maps/xz.gif);
        }
    }
    .yn {
        width: 92px;
        height: 92px;
        top: 313px;
        left: 229px;
        &:hover,
        &.on {
            background-image: url(../images/maps/yn.gif);
        }
    }
    .zj {
        width: 40px;
        height: 50px;
        top: 275px;
        left: 444px;
        &:hover,
        &.on {
            background-image: url(../images/maps/zj.gif);
        }
    }
    .sh {
        width: 50px;
        height: 30px;
        top: 259px;
        left: 480px;
    }
    @media screen and (max-width: 1024px) {
        margin: 0 auto;
    }
    @media screen and (max-width: 750px) {
        display: none;
    }
}

.store-form {
    form {
        display: flex;
    }
    select,
    input,
    button {
        border: none;
        outline: none;
        background: none;
    }
    .select-box {
        flex: 1;
        min-width: 0;
        position: relative;
        margin-right: 16px;
        height: 44px;
        background-color: #fff;
        border: 1px solid #666;
        overflow: hidden;
        box-sizing: border-box;
        select {
            opacity: 0;
            cursor: pointer;
            z-index: 5;
        }
        .map-select {
            display: block;
            padding: 0 12px;
            width: 100%;
            height: 100%;
            line-height: 42px;
            font-size: 12px;
            color: #4f4f4f;
            border: none;
            box-sizing: border-box;
            appearance: none;
            &::-ms-expand {
                display: none;
            }
            .customSelectInner {
                position: relative;
                vertical-align: top;
                overflow: hidden;
                white-space: nowrap;
                -ms-text-overflow: ellipsis;
                text-overflow: ellipsis;
                &:after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    border-style: solid;
                    border-width: 5px 4px 0;
                    border-color: #666 transparent transparent;
                    content: '';
                    margin-top: -2px;
                }
            }
        }
    }
    ::-webkit-input-placeholder {
        color: #868686;
    }
    ::-moz-placeholder {
        color: #868686;
    }
    :-ms-input-placeholder {
        color: #868686;
    }
    .search-box {
        display: flex;
        width: (615 / 1300) * 100%;
        .input {
            flex: 1;
            margin-right: 6px;
            background-color: #fff;
            border: 1px solid #666;
            overflow: hidden;
            box-sizing: border-box;
            input {
                display: block;
                padding: 0 12px;
                width: 100%;
                line-height: 42px;
                font-size: 12px;
                color: #4f4f4f;
                height: 100%;
                box-sizing: border-box;
            }
        }
        button {
            width: 110px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            background-color: $mainYellow;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                opacity: .8;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .select-box {
            margin-right: 12px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-top: px2rem(60px);
        form {
            flex-wrap: wrap;
        }
        .select-box {
            flex: 0 0 calc(50% - .075rem);
            margin-right: 0;
            height: 38px;
            & + .select-box {
                margin-left: px2rem(30px);
            }
            .map-select {
                line-height: 36px;
            }
        }
        .search-box {
            margin-top: px2rem(30px);
            width: 100%;
            height: 38px;
            .input {
                input {
                    padding-left: 15px;
                    line-height: 36px;
                }
            }
            button {
                width: 80px;
                height: 38px;
                line-height: 38px;
            }
        }
    }
}

.map-area {
    position: relative;
    display: flex;
    margin: 35px 0 -65px;
    padding: 32px;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    z-index: 2;
    &-province {
        position: relative;
        margin-right: 25px;
        padding-right: 30px;
        line-height: 1.4;
        font-size: 22px;
        color: #272727;
        &:before {
            content: '\e61e';
            display: inline-block;
            margin-right: 4px;
            font-family: 'iconfont';
            font-size: 24px;
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 6px;
            width: 1px;
            height: 70%;
            background-color: #e0e0e0;
        }
    }
    &-city {
        flex: 1;
        min-width: 0;
        font-size: 0;
        a {
            display: inline-block;
            vertical-align: top;
            margin: 6px 0 6px 12px;
            padding: 0 13px;
            line-height: 23px;
            font-size: 13px;
            color: #272727;
            background-color: #f9f9f9;
            border: 1px solid #e5e5e5;
            border-radius: 2px;
            overflow: hidden;
            cursor: pointer;
            transition: all .3s;
            &.on,
            &:hover {
                color: #fff;
                background-color: $mainYellow;
                border-color: $mainYellow;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding-top: 24px;
        padding-bottom: 24px;
        &-province {
            font-size: 20px;
            &:before {
                font-size: 22px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 20px 30px;
        &-province {
            padding-right: 25px;
            margin-right: 20px;
            font-size: 18px;
            &:before {
                font-size: 20px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        display: none;
    }
}

.store-page {
    padding: 115px 0 px2rem(85px);
    background-color: #fff;
    @media screen and (max-width: 1024px) {
        padding-top: 105px;
    }
    @media screen and (max-width: 750px) {
        padding-top: px2rem(70px);
    }
}

.store-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px 20px;
    li {
        padding: 0 20px 35px;
        width: 50%;
        box-sizing: border-box;
    }
    a {
        position: relative;
        display: flex;
        align-items: flex-start;
        padding: 35px 40px 35px 35px;
        height: 100%;
        background-color: #f5f5f5;
        border: 2px solid #f5f5f5;
        box-sizing: border-box;
        transition: all .3s;
        &:hover {
            background-color: #fff;
            border-color: $mainYellow;
            box-shadow: 0 px2rem(22px) px2rem(12px) px2rem(-16px) rgba(0, 0, 0, .2);
            .tag {
                opacity: 1;
            }
        }
    }
    .info {
        flex: 1;
        min-width: 0;
        h3 {
            margin-bottom: 10px;
            line-height: 1.5;
            font-size: 18px;
            color: #000;
            transition: all .3s;
            overflow: hidden;
        }
        &-p {
            display: flex;
            line-height: 28px;
            font-size: 14px;
            color: #6a6a6a;
            p {
                flex: 1;
                min-width: 0;
                overflow: hidden;
                white-space: nowrap;
                -ms-text-overflow: ellipsis;
                text-overflow: ellipsis;
            }
            .iconfont {
                margin-right: 5px;
                color: #838383;
                font-size: 14px;
            }
            .tel {
                font-family: Helvetica Neue;
                font-size: 15px;
            }
        }
    }
    .tag {
        margin: 4px 0 0 px2rem(20px);
        padding: 12px 10px 8px;
        width: 40px;
        text-align: center;
        line-height: 18px;
        color: #535353;
        font-size: 13px;
        border: 1px solid #707070;
        opacity: 0;
        transition: all .3s;
        &-cn {
            padding: 0 5px 4px;
        }
        &-en {
            opacity: .4;
        }
    }
    @media screen and (max-width: 1200px) {
        a {
            padding: 30px;
        }
        .info {
            h3 {
                font-size: 16px;
            }
            &-p {
                line-height: 26px;
                font-size: 13px;
                .iconfont {
                    font-size: 13px;
                }
                .tel {
                    font-size: 14px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 0 -15px 20px;
        li {
            padding: 0 15px 30px;
        }
        a {
            padding: 24px;
        }
        .info {
            h3 {
                font-size: 15px;
            }
            &-p {
                line-height: 26px;
                font-size: 13px;
                .iconfont {
                    font-size: 13px;
                }
                .tel {
                    font-size: 14px;
                }
            }
        }
        .tag {
            padding: 10px 6px 6px;
            width: 36px;
            line-height: 16px;
            font-size: 12px;
            opacity: 1;
            &-cn {
                padding: 0 3px 2px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        display: block;
        margin: 0 0 px2rem(10px);
        li {
            padding: 0 0 px2rem(40px);
            width: 100%;
            a {
                padding: px2rem(40px) px2rem(30px) px2rem(40px) px2rem(40px);
                border-width: 1px;
            }
            .info {
                h3 {
                    margin-bottom: 6px;
                }
                &-p {
                    line-height: 22px;
                    font-size: 11px;
                    .iconfont {
                        font-size: 11px;
                    }
                    .tel {
                        font-size: 13px;
                    }
                }
            }
            .tag {
                padding: 8px 6px 4px;
                line-height: 15px;
                font-size: 11px;
                &-cn {
                    padding: 0 3px 2px;
                }
            }
        }
    }
}