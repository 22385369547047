@import "//at.alicdn.com/t/font_2414526_w6kjduc4asb.css";
/* CSS Document */
//SCSS全局变量
$mainYellow: #c9a063;
$mainBlue: #083190;
// 默认移动端设计稿宽度
$baseDevice: 750;
// 前端切图移动端默认正常显示尺寸，默认为设计稿的一半
$device: $baseDevice / 2;
// 默认html font-size
$baseFontSize: 100px;
// scss function
@function calc-percent($target, $context) {
    @return $target/$context * 100%;
}
@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
    @if (unitless($px)) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
        @return px2rem($px + 0px); // That may fail.
    } @else if (unit($px)==rem) {
        @return $px;
    }
    @return ($px / $base-font-size) * 1rem;
}
//透明
@mixin touming($o: 70) {
    opacity: $o/100;
    -webkit-opacity: $o/100;
    filter: alpha(opacity=$o);
}
@mixin transition($time: .2s) {
    -webkit-transition: $time ease all;
    -moz-transition: $time ease all;
    transition: $time ease all;
}
@mixin articleReset($fontsize: 14px, $lineHieght: 30px) {
    font-size: $fontsize;
    line-height: $lineHieght;
    p {
        margin-bottom: $lineHieght/2;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    ul {
        list-style: disc;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        li {
            list-style: decimal;
        }
    }
    iframe {
        max-width: 100%;
    }
}
// 多行省略
@mixin multiEllipsis($line: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}
/* reset.css V1.6  Start*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
th,
td {
    font-weight: normal;
    margin: 0;
    padding: 0;
}
body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
i {
    font: normal 12px BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
ul,
ol,
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
}
a:hover {
    text-decoration: none;
}
img {
    vertical-align: top;
    border: 0;
}
button,
input,
textarea {
    font-size: 100%;
    vertical-align: middle;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
div,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
html {
    font-size: $baseFontSize;
}
@media screen and (min-width: 320px) {
    html {
        font-size: (320/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 360px) {
    html {
        font-size: (360/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 375px) {
    html {
        font-size: (375/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 480px) {
    html {
        font-size: (480/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 640px) {
    html {
        font-size: (640/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 750px) {
    html {
        font-size: (750/$device) * $baseFontSize;
    }
}
.clearfloat:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ' ';
}
.clearfloat {
    *zoom: 1;
}
.clearboth {
    clear: both;
}

.checkBrowser {
    font-size: 14px;
    line-height: 40px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 700px;
    height: 40px;
    margin-left: -360px;
    padding: 0 10px;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e6212a;
    a {
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
    }
}
/* reset.css V1.6  End */
body {
    background-color: #efeeee;
}

.theme-blue {
    .subbar {
        &-breadcon {
            a {
                &:hover {
                    color: $mainBlue;
                    .iconfont {
                        color: $mainBlue;
                    }
                }
            }
        }
        &-menu {
            .swiper-slide {
                a {
                    &:hover {
                        color: $mainBlue;
                    }
                }
                &.on {
                    a {
                        color: $mainBlue;
                        &:after {
                            background-color: $mainBlue;
                        }
                    }
                }
            }
        }
    }
    .pro-classify {
        &-list {
            .list {
                a {
                    &:hover,
                    &.on {
                        color: $mainBlue;
                    }
                }
            }
        }
    }
    .pro-series {
        background-image: url(../images/bg3.png);
        &-title {
            &:after {
                background-color: $mainBlue;
            }
        }
    }
    .pro-list {
        a {
            &:hover {
                h3 {
                    color: $mainBlue;
                }
            }
        }
    }
    .paged .current,
    .paged a:hover {
        border-color: $mainBlue;
        background: $mainBlue;
    }
}

@font-face {
    font-family: 'DINCond-Regular';
    src: url('../font/DINCond-Regular.eot');
    src: url('../font/DINCond-Regular.woff') format('woff'), url('../font/DINCond-Regular.ttf') format('truetype'), url('../font/DINCond-Regular.svg') format('svg');
    font-style: normal;
}

@font-face {
    font-family: 'DIN Bold';
    src: url('../font/DIN Bold.eot');
    src: url('../font/DIN Bold.woff') format('woff'), url('../font/DIN Bold.ttf') format('truetype'), url('../font/DIN Bold.svg') format('svg');
    font-style: normal;
}

@font-face {
    font-family: 'MyriadPro-Regular';
    src: url('../font/MyriadPro-Regular.eot');
    src: url('../font/MyriadPro-Regular.woff') format('woff'), url('../font/MyriadPro-Regular.ttf') format('truetype'), url('../font/MyriadPro-Regular.svg') format('svg');
    font-style: normal;
}

.wp1530 {
    max-width: 1530px;
    margin: 0 auto;
    @media screen and (max-width: 1590px) {
        max-width: 100%;
        padding: 0 px2rem(30px);
        box-sizing: border-box;
    }
}

.wp1460 {
    max-width: 1460px;
    margin: 0 auto;
    @media screen and (max-width: 1560px) {
        max-width: 100%;
        padding: 0 50px;
        box-sizing: border-box;
    }
    @media screen and (max-width: 1440px) {
        padding: 0 px2rem(30px);
    }
}

.wp1400 {
    max-width: 1400px;
    margin: 0 auto;
    @media screen and (max-width: 1540px) {
        max-width: 100%;
        padding: 0 70px;
        box-sizing: border-box;
    }
    @media screen and (max-width: 1440px) {
        padding: 0 px2rem(30px);
    }
}

.wp1300 {
    max-width: 1300px;
    margin: 0 auto;
    @media screen and (max-width: 1460px) {
        max-width: 100%;
        padding: 0 80px;
        box-sizing: border-box;
    }
    @media screen and (max-width: 1200px) {
        padding: 0 px2rem(30px);
    }
}

.wp1320 {
    max-width: 1320px;
    margin: 0 auto;
    @media screen and (max-width: 1420px) {
        max-width: 100%;
        padding: 0 50px;
        box-sizing: border-box;
    }
    @media screen and (max-width: 1200px) {
        padding: 0 px2rem(30px);
    }
}

.wp1200 {
    max-width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 1260px) {
        max-width: 100%;
        padding: 0 px2rem(30px);
        box-sizing: border-box;
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 999;
    &-block {
        height: 100px;
    }
    .wp1460 {
        display: flex;
        justify-content: space-between;
        height: 100px;
    }
    &-right {
        display: flex;
    }
    .logo {
        a {
            display: block;
            padding: 0 36px;
            line-height: 100px;
            background-color: #fff;
            img {
                display: inline-block;
                vertical-align: middle;
                height: auto;
                max-height: 70px;
            }
        }
    }
    &-nav {
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            &.on,
            &:hover {
                .nav-a {
                    color: $mainYellow;
                }
            }
        }
        .nav-a {
            display: block;
            margin: 0 25px;
            line-height: 100px;
            font-size: 16px;
            color: #000;
            transition: color .2s;
            &:hover {
                color: $mainYellow;
            }
        }
        .nav-sub {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            text-align: center;
            line-height: 80px;
            font-size: 0;
            background-color: rgba(255, 255, 255, .85);
            box-shadow: 0 2px 8px -3px rgba(0, 0, 0, .15);
            z-index: 9;
            a {
                display: inline-block;
                vertical-align: top;
                margin: 0 32px;
                font-size: 16px;
                color: #666;
                transition: all .3s;
                &:hover,
                &.on {
                    font-size: 20px;
                    color: $mainYellow;
                }
            }
        }
    }
    &-icon {
        display: flex;
        align-items: center;
        margin-right: 5px;
        .icon {
            margin-right: 12px;
            width: 32px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            font-size: 18px;
            color: #fff;
            background: #2b2b2b;
            border-radius: 100%;
            cursor: pointer;
            transition: all .2s;
            &#searchBtn {
                background-color: #fff;
                position: relative;
                &:hover {
                    .active {
                        opacity: 1;
                    }
                }
                .active {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    top: 0;
                    transition: .3s;
                    z-index: 5;
                }
            }
            img {
                width: 100%;
                height: 100%;
            }
            &:hover {
                background: $mainYellow;
            }
        }
    }
    &-search {
        display: none;
        position: fixed;
        top: 100px;
        left: 0;
        right: 0;
        padding: 20px 0;
        background-color: rgba(255, 255, 255, .85);
        box-shadow: 0 2px 8px -3px rgba(0, 0, 0, .15);
        z-index: 998;
        &-form {
            display: flex;
            margin: 0 auto;
            width: 480px;
            input,
            button {
                height: 40px;
                line-height: 40px;
                outline: none;
            }
            button {
                margin-left: 10px;
                width: 100px;
                text-align: center;
                font-size: 15px;
                color: #fff;
                background-color: $mainYellow;
                border: none;
                cursor: pointer;
                transition: all .3s;
                &:hover {
                    background-color: lighten($mainYellow, 5%);
                }
            }
            .input {
                flex: 1;
                min-width: 0;
                input {
                    display: block;
                    padding: 0 15px;
                    width: 100%;
                    font-size: 13px;
                    color: #666;
                    border: 1px solid #ccc;
                    background-color: #fff;
                    box-sizing: border-box;
                }
            }
        }
    }
    &-word {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 24px;
        .s {
            display: block;
            font-size: 12px;
            color: rgba(#000, .7);
            .num {
                font-size: 12px;
            }
        }
        .p {
            font-size: 20px;
            color: #000;
            .num {
                font-size: 24px;
            }
        }
        .num {
            display: inline-block;
            font-family: 'DIN Bold';
        }
    }
    &-menu {
        display: none;
        line-height: px2rem(100px);
        font-size: px2rem(40px);
        color: #fff;
        &:before {
            content: '\e63a';
        }
        &.active {
            &:before {
                content: '\e642';
            }
        }
    }
    @media screen and (max-width: 1440px) {
        &-block {
            height: 90px;
        }
        .wp1460 {
            height: 90px;
        }
        .logo {
            a {
                padding: 0 20px;
                line-height: 90px;
                img {
                    max-height: 54px;
                }
            }
        }
        &-nav {
            .nav-a {
                margin: 0 20px;
                line-height: 90px;
                font-size: 15px;
            }
            .nav-sub {
                line-height: 70px;
                a {
                    margin: 0 26px;
                    font-size: 15px;
                    &:hover,
                    &.on {
                        font-size: 18px;
                    }
                }
            }
        }
        &-search {
            top: 90px;
            padding: 15px 0;
        }
        &-word {
            line-height: 22px;
            .s {
                font-size: 13px;
                .num {
                    font-size: 15px;
                }
            }
            .p {
                font-size: 17px;
                .num {
                    font-size: 19px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .wp1460 {
            height: px2rem(100px);
        }
        &-block {
            height: px2rem(100px);
        }
        .logo {
            a {
                padding: 0 px2rem(30px);
                line-height: px2rem(100px);
                img {
                    max-height: px2rem(56px);
                }
            }
        }
        &-nav {
            display: none;
        }
        &-word {
            display: none;
        }
        &-icon {
            .icon {
                margin-right: px2rem(20px);
                width: px2rem(46px);
                height: px2rem(46px);
                line-height: px2rem(46px);
                font-size: px2rem(24px);
            }
        }
        &-menu {
            display: block;
        }
        &-search {
            top: px2rem(100px);
            padding: px2rem(30px) px2rem(60px);
            box-sizing: border-box;
            &-form {
                width: 700px;
                max-width: 100%;
                input,
                button {
                    height: px2rem(70px);
                    line-height: px2rem(70px);
                    outline: none;
                }
                button {
                    margin-left: px2rem(15px);
                    width: px2rem(140px);
                    font-size: px2rem(28px);
                }
                .input {
                    input {
                        padding: 0 px2rem(20px);
                        font-size: px2rem(24px);
                    }
                }
            }
        }
    }
}

.nav-mobile {
    position: fixed;
    top: px2rem(100px);
    right: 0;
    bottom: 0;
    width: px2rem(480px);
    transform: translateX(100%);
    background-color: #f5f5f5;
    box-sizing: border-box;
    overflow: hidden;
    transition: transform .5s ease;
    z-index: 6;
    &.anim {
        transform: translateX(0);
    }
    &-con {
        display: none;
        position: fixed;
        top: px2rem(100px);
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 1000;
        transition: transform .4s ease;
        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .4);
            z-index: 1;
        }
    }
    &-wp {
        position: relative;
        & > li {
            border-bottom: 1px solid #e2e2e2;
        }
    }
    &-title {
        position: relative;
        > a {
            position: relative;
            display: block;
            padding: 0 px2rem(30px) 0 px2rem(65px);
            width: 100%;
            line-height: px2rem(100px);
            font-size: px2rem(28px);
            color: #333;
            z-index: 2;
            backface-visibility: hidden;
            box-sizing: border-box;
        }
        &.open {
            .nav-mobile-child-btn i {
                &:before {
                    content: '\e630';
                }
            }
        }
    }
    &-child-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 px2rem(30px) 0 px2rem(65px);
        text-align: right;
        line-height: px2rem(100px);
        box-sizing: border-box;
        z-index: 1;
        i {
            font-size: px2rem(22px);
            color: #333;
            font-family: "iconfont";
            transition: all .3s;
            &:before {
                content: "\e610";
            }
        }
        + a {
            display: inline-block;
            width: auto;
        }
    }
    .sub {
        display: none;
        border-top: 1px solid #e2e2e2;
        li {
            position: relative;
            overflow: hidden;
            &:not(:last-child) a {
                border-bottom: 1px solid #e2e2e2;
            }
            a {
                display: block;
                padding: 0 px2rem(30px) 0 px2rem(65px);
                line-height: px2rem(100px);
                height: px2rem(100px);
                font-size: px2rem(26px);
                color: #999;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }
}

.footer {
    &-top {
        padding: 75px 0 95px;
        background-color: #cecdca;
        .wp1460 {
            display: flex;
        }
    }
    &-nav {
        flex: 1;
        min-width: 0;
        display: flex;
        line-height: 34px;
        color: #f5f4f0;
        a {
            color: #000;
            transition: all .3s;
            &:hover {
                opacity: 1;
                color: $mainYellow;
            }
        }
        dl {
            padding: 0 45px 0 30px;
        }
        dt {
            margin-bottom: 6px;
            a {
                font-size: 16px;
            }
        }
        dd {
            a {
                font-size: 13px;
                opacity: .8;
            }
        }
    }
    &-contact {
        padding-right: 30px;
        width: 180px;
        &-top {
            display: flex;
            align-items: center;
            line-height: 1.1;
            .text {
                font-size: 10px;
                color: #000;
                -webkit-transform-origin: left center;
                -webkit-transform: scale(.83);
            }
            .tel {
                position: relative;
                flex: 1;
                min-width: 0;
                padding-left: 14px;
                font-family: 'DIN Bold';
                font-size: 24px;
                color: #333;
                -webkit-transform: translateX(-3px);
                white-space: nowrap;
                &:after {
                    content: '';
                    position: absolute;
                    left: 6px;
                    top: 50%;
                    margin-top: -8px;
                    width: 1px;
                    height: 16px;
                    background-color: #fff;
                }
            }
        }
        &-qrcode {
            margin-top: 8px;
            padding: 18px 18px 12px;
            text-align: center;
            line-height: 1.3;
            font-size: 14px;
            color: #666;
            background-color: #fff;
            img {
                display: block;
                margin-bottom: 5px;
                width: 100%;
                height: auto;
            }
        }
    }
    &-content {
        padding: 30px 0;
        text-align: center;
        line-height: 28px;
        font-size: 14px;
        color: #000;
        background-color: #cecdca;
        a {
            color: #000;
            transition: all .3s;
            &:hover {
                color: $mainYellow;
            }
        }
        a,
        span {
            display: inline-block;
            vertical-align: top;
            padding: 0 8px;
        }
        .beian-icon {
            display: inline-block;
            vertical-align: middle;
            margin: -3px 6px 0 0;
            width: 16px;
            height: auto;
        }
    }
    @media screen and (max-width: 1440px) {
        &-nav {
            line-height: 32px;
            dl {
                padding: 0 40px 0 30px;
            }
            dt {
                margin-bottom: 4px;
                a {
                    font-size: 15px;
                }
            }
            dd {
                a {
                    font-size: 12px;
                }
            }
        }
        &-contact {
            width: 170px;
            &-top {
                .tel {
                    font-size: 22px;
                    &:after {
                        margin-top: -7px;
                        height: 14px;
                    }
                }
            }
            &-qrcode {
                margin-top: 6px;
                padding: 15px 15px 12px;
                font-size: 13px;
            }
        }
        &-content {
            line-height: 26px;
            font-size: 13px;
            a,
            span {
                padding: 0 6px;
            }
            .beian-icon {
                width: 14px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-top {
            padding: 65px 0 80px;
        }
        &-nav {
            dl {
                padding: 0 60px 0 0;
            }
        }
        &-contact {
            padding-right: 0;
        }
    }
    @media screen and (max-width: 1024px) {
        &-top {
            padding: px2rem(70px) 0 px2rem(80px);
            .wp1460 {
                justify-content: center;
            }
        }
        &-nav {
            display: none;
        }
        &-content {
            padding: 24px 0 calc(24px + .68rem);
        }
    }
    @media screen and (max-width: 750px) {
        &-content {
            a,
            span {
                padding: 0 4px;
            }
            .beian-icon {
                margin: -2px 4px 0 0;
            }
        }
    }
}

.rightbar {
    position: fixed;
    bottom: 15%;
    right: 0;
    width: 80px;
    z-index: 999;
    li {
        & + li {
            margin-top: 10px;
        }
        &:hover {
            .a-box {
                background-color: $mainYellow;
            }
        }
    }
    .a-box {
        display: flex;
        height: 80px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        line-height: 1.2;
        color: #fff;
        background-color: #2b2b2b;
        cursor: pointer;
        transition: all .3s;
    }
    .icon {
        margin-bottom: 5px;
        font-size: 26px;
    }
    p {
        font-size: 14px;
    }
    @media screen and (max-width: 1440px) {
        width: 65px;
        li {
            & + li {
                margin-top: 6px;
            }
        }
        .a-box {
            height: 65px;
        }
        .icon {
            font-size: 22px;
        }
        p {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 1200px) {
        display: none;
    }
}

.tabbar-nav {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: px2rem(136px);
    justify-content: space-around;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, .1);
    z-index: 999;
    a {
        display: block;
        padding: px2rem(18px) 0 8px;
        height: 100%;
        box-sizing: border-box;
        &.on {
            .icon,
            p {
                color: $mainYellow;
            }
        }
    }
    .icon {
        margin-bottom: px2rem(8px);
        font-size: px2rem(46px);
        color: #171717;
    }
    p {
        line-height: 1.2;
        font-size: px2rem(24px);
        color: #333;
    }
    @media screen and (max-width: 1024px) {
        display: flex;
    }
}

.inside-banner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: (400 / 1920) * 100%;
    .img-pc,
    .img-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .img-mobile {
        display: none;
    }
    @media screen and (max-width: 750px) {
        padding-bottom: (320 / 750) * 100%;
        .img-pc {
            display: none;
        }
        .img-mobile {
            display: block;
        }
    }
}

.pro-banner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: (600 / 1920) * 100%;
    .img-pc,
    .img-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .img-mobile {
        display: none;
    }
    @media screen and (max-width: 750px) {
        padding-bottom: (400 / 750) * 100%;
        .img-pc {
            display: none;
        }
        .img-mobile {
            display: block;
        }
    }
}

.advantage-banner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: (650 / 1920) * 100%;
    .img-pc,
    .img-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .img-mobile {
        display: none;
    }
    @media screen and (max-width: 750px) {
        padding-bottom: (450 / 750) * 100%;
        .img-pc {
            display: none;
        }
        .img-mobile {
            display: block;
        }
    }
}

.subbar {
    line-height: 80px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e2e2e2;
    .wp1460 {
        display: flex;
    }
    &-breadcon {
        font-size: 14px;
        color: #888;
        a {
            color: #888;
            transition: all .3s;
            &:hover {
                color: $mainYellow;
                .iconfont {
                    color: $mainYellow;
                }
            }
        }
        a,
        span {
            display: inline-block;
            vertical-align: top;
        }
        .icon-shouye {
            margin-right: 2px;
            font-size: 16px;
            color: #3e3e3e;
        }
    }
    &-menu {
        flex: 1;
        min-width: 0;
        text-align: right;
        &.no-init {
            .swiper-wrapper {
                display: block;
                width: 100%;
            }
        }
        .swiper-wrapper {
            font-size: 0;
        }
        .swiper-slide {
            display: inline-block;
            vertical-align: top;
            width: auto;
            a {
                position: relative;
                display: block;
                margin: 0 17px;
                padding: 0 12px;
                font-size: 16px;
                color: #666;
                transition: all .3s;
                &:hover {
                    color: $mainYellow;
                }
            }
            &.on {
                a {
                    color: $mainYellow;
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        right: 0;
                        height: 2px;
                        background-color: $mainYellow;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        line-height: 70px;
        &-breadcon {
            font-size: 13px;
            .icon-shouye {
                font-size: 15px;
            }
        }
        &-menu {
            .swiper-slide {
                a {
                    margin: 0 15px;
                    padding: 0 10px;
                    font-size: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        line-height: 60px;
        .wp1460 {
            display: block;
            padding: 0;
        }
        &-breadcon {
            display: none;
        }
        &-menu {
            text-align: center;
        }
    }
    @media screen and (max-width: 750px) {
        line-height: 45px;
        &-menu {
            .swiper-slide {
                a {
                    margin: 0 12px;
                    padding: 0 5px;
                    font-size: 14px;
                }
            }
        }
    }
}

.inside-page {
    padding: 75px 0 95px;
    background: #efeeee url(../images/bg1.png) bottom right no-repeat;
    background-size: (507 / 1920) * 100%, auto;
    @media screen and (max-width: 1024px) {
        padding: px2rem(70px) 0 px2rem(85px);
        background-size: (507 / 1024) * 100%, auto;
    }
    @media screen and (max-width: 750px) {
        padding: 0;
        background: none;
        .wp1460,
        .wp1200 {
            padding: 0;
        }
    }
}

.list-page {
    padding: px2rem(40px) 0 90px;
    background: #efeeee url(../images/bg1.png) bottom right no-repeat;
    background-size: (507 / 1920) * 100%, auto;
    @media screen and (max-width: 1024px) {
        padding-bottom: px2rem(80px);
        background-size: (400 / 1024) * 100%, auto;
    }
    @media screen and (max-width: 750px) {
        background-image: url(../images/bg2.png);
        background-size: (300 / 750) * 100%, auto;
    }
}

// page CSS样式
.paged {
    text-align: center;
    font-size: 0;
}

.paged a,
.paged .p_info,
.paged .current {
    display: inline-block;
    vertical-align: top;
    margin: 14px 7px 0;
    padding: 0 15px;
    font-size: 13px;
    text-align: center;
    border: 1px solid #dedede;
    background: #fff;
    color: #8b8b8b;
    border-radius: 3px;
    height: 38px;
    line-height: 38px;
    @media screen and (max-width: 1024px) {
        margin: 10px 5px 0;
        height: 33px;
        line-height: 33px;
        padding: 0 12px;
        font-size: 12px;
    }
    @media screen and (max-width: 750px) {
        margin: 6px 3px 0;
        height: 27px;
        line-height: 27px;
        padding: 0 10px;
        font-size: 11px;
    }
}
.paged .current,
.paged a:hover {
    border: 1px solid $mainYellow;
    background: $mainYellow;
    color: #fff;
}