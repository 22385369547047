.captcha {
    &-title {
        height: 46px;
        width: 100%;
        text-align: center;
        border-radius: 2px;
        background-color: #f3f3f3;
        color: #bbb;
        font-size: 14px;
        letter-spacing: .1px;
        line-height: 60px;
    }

    &-show {
        display: none;
        height: 46px;
        width: 100%;
        text-align: center;
        border-radius: 2px;
        background-color: #f3f3f3;
    }

    &-loading {
        margin: auto;
        width: 70px;
        height: 20px;
    }

    &-loading-dot {
        float: left;
        width: 8px;
        height: 8px;
        margin: 18px 4px;
        background: #ccc;
        border-radius: 50%;
        opacity: 0;
        box-shadow: 0 0 2px black;
        animation: loadingFade 1s infinite;

        &:nth-child(2) {
            animation-delay: .1s
        }

        &:nth-child(3) {
            animation-delay: .2s
        }

        &:nth-child(4) {
            animation-delay: .3s
        }
    }
    .geetest_holder.geetest_wind {
        height: 46px;
    }

    .geetest_holder.geetest_wind .geetest_radar_tip,
    .geetest_holder.geetest_wind .geetest_success_radar_tip {
        height: 46px;
        line-height: 46px;
        padding: 0 46px;
    }
    .geetest_holder.geetest_wind .geetest_radar {
        margin: 10px;
    }
    .geetest_holder.geetest_wind .geetest_radar_btn,
    .geetest_holder.geetest_wind .geetest_success_btn {
        border: none;
        background: #fff;
        &:hover {
            background: rgba(255, 255, 255, .8);
        }
    }
    .geetest_holder.geetest_wind .geetest_logo,
    .geetest_holder.geetest_wind .geetest_success_logo {
        top: 14px;
    }
    .geetest_holder.geetest_wind .geetest_success_btn .geetest_success_box {
        top: 14px;
        left: 18px;
    }
    .geetest_holder.geetest_wind .geetest_success_btn .geetest_success_box .geetest_success_show {
        background: none;
    }
    .geetest_holder.geetest_wind .geetest_radar .geetest_ring,
    .geetest_holder.geetest_wind .geetest_radar .geetest_sector {
        box-shadow: inset 0 0 0 1px $mainYellow;
        background: #ffce59;
    }
    .geetest_holder.geetest_wind .geetest_radar .geetest_sector {
        background-color: #ffce59;
        background-image: linear-gradient(115deg, rgba(0, 0, 0, 0) 50%, #faf1da 50%), linear-gradient(65deg, #faf1da 50%, rgba(0, 0, 0, 0) 50%);
    }
    .geetest_holder.geetest_wind.geetest_compute_1 .geetest_radar .geetest_ring,
    .geetest_holder.geetest_wind.geetest_compute_2 .geetest_radar .geetest_ring {
        box-shadow: inset 0 0 0 2px $mainYellow;
    }
    .geetest_holder.geetest_wind.geetest_radar_click_hide .geetest_radar .geetest_dot,
    .geetest_holder.geetest_wind.geetest_slide_click_hide .geetest_radar .geetest_dot,
    .geetest_holder.geetest_wind.geetest_detect .geetest_radar .geetest_dot,
    .geetest_holder.geetest_wind.geetest_wait_compute .geetest_radar .geetest_dot,
    .geetest_holder.geetest_wind.geetest_start_detect .geetest_radar .geetest_dot,
    .geetest_holder.geetest_wind.geetest_start_compute .geetest_radar .geetest_dot,
    .geetest_holder.geetest_wind.geetest_compute_1 .geetest_radar .geetest_dot,
    .geetest_holder.geetest_wind.geetest_compute_2 .geetest_radar .geetest_dot {
        background: $mainYellow;
    }
    @media screen and (max-width: 750px) {
        &-title {
            height: 40px;
            line-height: 40px;
        }

        &-show {
            height: 40px;
        }
        .geetest_holder.geetest_wind {
            height: 40px;
        }

        .geetest_holder.geetest_wind .geetest_radar_tip,
        .geetest_holder.geetest_wind .geetest_success_radar_tip {
            height: 40px;
            line-height: 40px;
            padding: 0 46px;
        }
        .geetest_holder.geetest_wind .geetest_logo,
        .geetest_holder.geetest_wind .geetest_success_logo {
            top: 10px;
            right: 12px;
        }
        .geetest_holder.geetest_wind .geetest_radar {
            margin: 5px 12px;
        }
        .geetest_holder.geetest_wind .geetest_wait {
            margin: 15px 12px;
        }
        .geetest_holder.geetest_wind .geetest_success_btn .geetest_success_box {
            top: 9px;
            left: 10px;
        }
    }
}

@keyframes loadingFade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .8;
    }

    100% {
        opacity: 0;
    }
}
