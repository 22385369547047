.article-swiper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    touch-action: none;
    z-index: 9999;
    .swiper-pagination {
        bottom: 1em;
        color: #fff;
    }
    .swiper-slide {
        img {
            display: block;
            max-width: 95%;
            max-height: calc(100% - 6em);
        }
        p {
            position: absolute;
            top: 1em;
            left: 0;
            padding: 0 px2rem(30px);
            width: 100%;
            text-align: center;
            line-height: 1.6;
            font-size: 13px;
            color: #fff;
            box-sizing: border-box;
            z-index: 22;
        }
    }
}

.about-article {
    padding: 80px;
    @include articleReset(16px, 30px);
    color: #666;
    background-color: #fff;
    p {
        margin-bottom: 30px;
    }
    &-title {
        margin-bottom: 45px;
        p {
            margin-bottom: 0;
            line-height: 1.2;
            font-size: 18px;
            font-weight: 300;
            text-transform: uppercase;
        }
        .h3 {
            margin-bottom: 5px;
            line-height: 1.4;
            font-size: 30px;
            color: #333;
        }
        .red {
            color: #e70012;
        }
    }
    &-img {
        padding: 15px 0;
    }
    @media screen and (max-width: 1440px) {
        padding: px2rem(70px);
        font-size: 14px;
        line-height: 28px;
        p {
            margin-bottom: 25px;
        }
        &-title {
            margin-bottom: 40px;
            p {
                font-size: 16px;
            }
            .h3 {
                font-size: 28px;
            }
        }
        &-img {
            padding: 12px 0;
        }
    }
    @media screen and (max-width: 1024px) {
        padding-left: px2rem(30px);
        padding-right: px2rem(30px);
        p {
            margin-bottom: 20px;
        }
        &-title {
            margin-bottom: 30px;
            p {
                font-size: 15px;
            }
            .h3 {
                font-size: 26px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        font-size: 13px;
        line-height: 26px;
        p {
            margin-bottom: 15px;
        }
        &-title {
            margin-bottom: 18px;
            p {
                font-size: 12px;
            }
            .h3 {
                margin-bottom: 2px;
                font-size: 22px;
            }
        }
        &-img {
            padding: 10px 0;
        }
    }
}

.honor-prev,
.honor-next {
    position: absolute;
    color: #666;
    outline: none;
    z-index: 88;
    cursor: pointer;
    transition: all .3s;
    &:hover {
        color: $mainYellow;
    }
}

.honor-box {
    position: relative;
    padding: 50px 40px 70px;
    background-color: #fff;
    overflow: hidden;
    &-swiper {
        .swiper-slide {
            width: 25%;
            cursor: pointer;
            &:hover {
                .img:after {
                    opacity: 1;
                }
                h3 {
                    color: $mainYellow;
                }
            }
        }
        .img {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: (540 / 750) * 100%;
            overflow: hidden;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 2px solid #dfdfdf;
                box-sizing: border-box;
                z-index: 2;
            }
            &:after {
                content: '\e603';
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                font-family: iconfont;
                font-size: 34px;
                color: #fff;
                background: rgba(0, 0, 0, .4);
                opacity: 0;
                z-index: 1;
                transition: all .3s;
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }
        h3 {
            margin-top: 14px;
            line-height: 1.5;
            text-align: center;
            font-size: 16px;
            color: #000;
            overflow: hidden;
            white-space: nowrap;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            transition: all .3s;
        }
    }
    .honor-prev,
    .honor-next {
        margin-top: -40px;
        top: 50%;
        font-size: 24px;
    }
    .honor-prev {
        left: 10px;
    }
    .honor-next {
        right: 10px;
    }
    @media screen and (max-width: 1440px) {
        &-swiper {
            h3 {
                font-size: 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 40px px2rem(40px) 50px;
        &-swiper {
            .swiper-slide {
                width: (100% / 3);
            }
            .img {
                &:after {
                    font-size: 26px;
                }
            }
            h3 {
                margin-top: 8px;
                font-size: 14px;
            }
        }
        .honor-prev,
        .honor-next {
            margin-top: -32px;
            width: px2rem(40px);
            text-align: center;
            font-size: 20px;
        }
        .honor-prev {
            left: 0;
        }
        .honor-next {
            right: 0;
        }
    }
    @media screen and (max-width: 750px) {
        padding-top: px2rem(70px);
        padding-bottom: px2rem(80px);
        &-swiper {
            .swiper-slide {
                width: 50%;
            }
            .img {
                &:after {
                    font-size: 22px;
                }
            }
            h3 {
                font-size: 12px;
            }
        }
        .honor-prev,
        .honor-next {
            margin-top: -22px;
            font-size: 16px;
        }
    }
}

.honor-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    z-index: 9000;
    &.init {
        display: flex;
    }
    &.show {
        .honor-popup-box {
            opacity: 1;
            transform: scale(1);
            transition-delay: .2s;
        }
        .honor-popup-mask {
            opacity: 1;
            transition-delay: 0s;
        }
    }
    &-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        opacity: 0;
        z-index: 1;
        transition: all .3s;
    }
    &-close {
        position: absolute;
        top: 23px;
        left: 100%;
        line-height: 60px;
        width: 60px;
        text-align: center;
        font-size: 24px;
        font-family: 'iconfont';
        color: #fff;
        background-color: $mainYellow;
        z-index: 2;
        cursor: pointer;
        transition: all .3s;
        &:before {
            content: '\e642';
        }
        &:hover {
            opacity: .8;
        }
    }
    &-box {
        position: relative;
        padding: 80px 85px 60px;
        width: 930px;
        background-color: #fff;
        box-shadow: 0 0 30px rgba(0, 0, 0, .1);
        border-radius: 4px;
        transform: scale(.9);
        box-sizing: border-box;
        opacity: 0;
        z-index: 6;
        transition: all .4s;
        .honor-prev,
        .honor-next {
            top: 340px;
            font-size: 26px;
        }
        .honor-prev {
            left: 45px;
        }
        .honor-next {
            right: 45px;
        }
        .img {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: (540 / 750) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
                max-height: 100%;
            }
        }
        .title {
            margin: 24px 0 5px;
            line-height: 1.5;
            font-size: 24px;
            color: #444;
        }
        .time {
            font-size: 16px;
            color: #777;
        }
    }
    @media screen and (max-width: 1440px) {
        &-close {
            line-height: 50px;
            width: 50px;
            font-size: 20px;
        }
        &-box {
            padding: 60px 60px 50px;
            width: 750px;
            .honor-prev,
            .honor-next {
                top: 280px;
            }
            .honor-prev {
                left: 24px;
            }
            .honor-next {
                right: 24px;
            }
            .title {
                margin-top: 20px;
                font-size: 22px;
            }
            .time {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-box {
            padding: 50px 50px 40px;
            width: 650px;
            .honor-prev,
            .honor-next {
                top: 240px;
                font-size: 24px;
            }
            .honor-prev {
                left: 20px;
            }
            .honor-next {
                right: 20px;
            }
            .title {
                font-size: 20px;
            }
            .time {
                font-size: 13px;
            }
        }
    }
    @media screen and (max-width: 750px), (max-height: 650px) {
        &-close {
            top: 0;
            left: auto;
            right: 0;
            line-height: 40px;
            width: 40px;
            font-size: 16px;
        }
        &-swiper {
            height: 100%;
        }
        &-box {
            padding: 60px px2rem(50px) px2rem(80px);
            width: 100%;
            height: 100%;
            border-radius: 0;
            .honor-prev,
            .honor-next {
                top: 50%;
                margin-top: -40px;
                width: px2rem(50px);
                text-align: center;
                font-size: px2rem(40px);
            }
            .honor-prev {
                left: 0;
            }
            .honor-next {
                right: 0;
            }
            .swiper-slide {
                display: flex;
                flex-direction: column;
            }
            .img {
                flex: 1;
                min-height: 0;
                height: auto;
                padding-bottom: 0;
            }
            .title {
                margin: 15px 0 2px;
                font-size: 18px;
            }
            .time {
                font-size: 12px;
            }
        }
    }
}

.contact-box {
    padding: 65px 60px 70px;
    background-color: #fff;
    @media screen and (max-width: 1440px) {
        padding: 60px 50px 70px;
    }
    @media screen and (max-width: 1200px) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @media screen and (max-width: 1024px) {
        padding: 50px px2rem(30px) 30px;
    }
    @media screen and (max-width: 750px) {
        padding-top: px2rem(65px);
        padding-bottom: px2rem(80px);
    }
}

.contact-article {
    padding-bottom: 15px;
    @include articleReset(18px, 30px);
    color: #666;
    p {
        margin-bottom: 0;
    }
    @media screen and (max-width: 1440px) {
        line-height: 28px;
        font-size: 16px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 15px;
    }
    @media screen and (max-width: 1024px) {
        line-height: 26px;
        font-size: 14px;
    }
    @media screen and (max-width: 750px) {
        padding-bottom: 10px;
        line-height: 24px;
        font-size: 13px;
    }
}

.contact-title {
    margin-bottom: 60px;
    &-h2 {
        line-height: 1.5;
        font-size: 30px;
        color: #333;
    }
    p {
        line-height: 1.2;
        font-size: 16px;
        font-weight: 300;
        color: #666;
        text-transform: uppercase;
    }
    @media screen and (max-width: 1440px) {
        margin-bottom: 50px;
        &-h2 {
            font-size: 28px;
        }
        p {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 1200px) {
        &-h2 {
            font-size: 26px;
        }
        p {
            font-size: 13px;
        }
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 40px;
        &-h2 {
            font-size: 24px;
        }
        p {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 20px;
        &-h2 {
            font-size: 20px;
        }
        p {
            font-size: 11px;
        }
    }
}

.contact-list {
    font-size: 0;
    li {
        display: inline-flex;
        vertical-align: top;
        margin: 0 52px 10px 0;
        list-style: none !important;
    }
    .icon {
        margin-right: 10px;
        img {
            display: inline-block;
            vertical-align: middle;
            margin-top: -3px;
            max-width: 22px;
        }
    }
    p {
        font-size: 18px;
    }
    @media screen and (max-width: 1440px) {
        .icon {
            margin-top: -2px;
            img {
                max-width: 18px;
            }
        }
        p {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 1200px) {
        li {
            margin-right: 40px;
        }
        .icon {
            margin-top: 0;
        }
        p {
            font-size: 15px;
        }
    }
    @media screen and (max-width: 1024px) {
        li {
            margin-right: 35px;
        }
        .icon {
            img {
                max-width: 16px;
            }
        }
        p {
            font-size: 14px;
        }
    }
    @media screen and (max-width: 750px) {
        li {
            margin: 0 30px 8px 0;
        }
        .icon {
            margin-right: 8px;
            img {
                max-width: 14px;
            }
        }
        p {
            font-size: 13px;
        }
    }
}

.contact-map {
    height: 560px;
    border: 1px solid #ececec;
    .map {
        display: block;
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: 1200px) {
        height: 480px;
    }
    @media screen and (max-width: 1024px) {
        height: 420px;
    }
    @media screen and (max-width: 750px) {
        height: px2rem(500px);
    }
}

.news-detail {
    padding: 75px 140px;
    background-color: #fff;
    &-top {
        margin-bottom: 30px;
        padding-bottom: 28px;
        text-align: center;
        border-bottom: 2px dashed #e6e6e6;
        h2 {
            margin-bottom: 12px;
            line-height: 1.4;
            font-size: 30px;
            color: #333;
        }
        p {
            font-size: 14px;
            color: #666;
            span {
                display: inline-block;
                vertical-align: top;
                margin: 0 8px;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        padding: 70px 100px;
        &-top {
            h2 {
                font-size: 26px;
            }
            p {
                font-size: 13px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding-left: 80px;
        padding-right: 80px;
    }
    @media screen and (max-width: 1024px) {
        padding: px2rem(65px) px2rem(30px);
        &-top {
            margin-bottom: 24px;
            h2 {
                font-size: 24px;
            }
            p {
                font-size: 12px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-top {
            margin-bottom: 18px;
            padding-bottom: 20px;
            border-bottom-width: 1px;
            h2 {
                margin-bottom: 10px;
                font-size: 20px;
            }
            p {
                font-size: 11px;
            }
        }
    }
}

.news-article {
    @include articleReset(16px, 34px);
    color: #666;
    p {
        margin-bottom: 30px;
    }
    .img {
        padding: 8px 0;
    }
    @media screen and (max-width: 1440px) {
        line-height: 30px;
        font-size: 15px;
        p {
            margin-bottom: 25px;
        }
    }
    @media screen and (max-width: 1024px) {
        p {
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 750px) {
        p {
            margin-bottom: 15px;
        }
        .img {
            padding: 6px 0;
        }
    }
}

.news-paged {
    display: flex;
    margin-top: px2rem(30px);
    text-align: center;
    line-height: 56px;
    background-color: #fff;
    a {
        flex: 1;
        min-width: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #000;
        transition: all .3s;
        & + a {
            border-left: 1px solid #efeeee;
        }
        i {
            font-size: 18px;
            color: #8f8f8f;
            transition: all .3s;
        }
        &:hover {
            color: $mainYellow;
            i {
                color: $mainYellow;
            }
        }
    }
    .prev,
    .backlist {
        i {
            margin-right: px2rem(12px);
        }
    }
    .next {
        i {
            margin-left: px2rem(12px);
        }
    }
    @media screen and (max-width: 1440px) {
        a {
            i {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        line-height: 46px;
    }
    @media screen and (max-width: 750px) {
        margin-bottom: px2rem(80px);
        line-height: 40px;
        a {
            i {
                font-size: 14px;
            }
        }
    }
}

.pro-article {
    @include articleReset(14px, 36px);
    color: #76645c;
    p {
        margin-bottom: 20px;
    }
    .font20 {
        font-size: 20px;
        color: #433a36;
    }
    .font16 {
        font-size: 16px;
    }
    &-title {
        margin-bottom: 20px;
        .h3 {
            line-height: 1.4;
            font-size: 26px;
            color: #3f2f22;
        }
        .en {
            margin-top: 10px;
            line-height: 1.2;
            text-transform: uppercase;
            font-family: 'MyriadPro-Regular';
            font-size: 14px;
            color: #9f8671;
            letter-spacing: 2px;
        }
        p {
            margin-top: 6px;
            line-height: 1.6;
            font-size: 20px;
            color: #76645c;
        }
    }
    @media screen and (max-width: 1200px) {
        line-height: 32px;
        .font20 {
            font-size: 18px;
        }
        .font16 {
            font-size: 15px;
        }
        &-title {
            .h3 {
                font-size: 24px;
            }
            .en {
                margin-top: 8px;
                font-size: 13px;
            }
            p {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        line-height: 28px;
        font-size: 13px;
        p {
            margin-bottom: 15px;
        }
        .font20 {
            margin-bottom: 2px;
            font-size: 17px;
        }
        &-title {
            margin-bottom: 15px;
            .h3 {
                font-size: 20px;
            }
            .en {
                margin-top: 4px;
                font-size: 10px;
            }
            p {
                margin-top: 4px;
                font-size: 16px;
            }
        }
    }
}

.pro-detail {
    padding: 50px 70px;
    background-color: #fff;
    &-title {
        margin-bottom: 50px;
        padding-bottom: 20px;
        text-align: center;
        line-height: 1.4;
        border-bottom: 1px solid #eee;
        p {
            font-size: 20px;
            color: #666;
        }
        h2 {
            font-size: 28px;
            line-height: inherit;
            color: #3f2f22;
        }
    }
    &-concept {
        display: flex;
        margin-bottom: 90px;
        .img {
            width: (600 / 1060) * 100%;
            img {
                display: block;
                width: 100%;
            }
        }
        .info {
            flex: 1;
            min-width: 0;
            padding: 50px 0 0 55px;
        }
        .pro-article-title {
            margin-bottom: 15px;
        }
    }
    &-series {
        display: flex;
        margin-bottom: 90px;
        .img {
            width: (530 / 1060) * 100%;
            img {
                display: block;
                width: 100%;
            }
        }
        .info {
            flex: 1;
            min-width: 0;
            margin-right: 50px;
        }
        p {
            margin-bottom: 0;
        }
        .pro-article-title {
            margin-bottom: 15px;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -13px;
            line-height: 22px;
            text-align: center;
            li {
                padding: 13px;
                width: (100% / 3);
                list-style: none !important;
                box-sizing: border-box;
            }
            .box {
                padding: 24px 10px;
                height: 100%;
                background-color: #f6f3ed;
                border: 1px solid #c2afa0;
                border-radius: 4px;
                overflow: hidden;
                box-sizing: border-box;
            }
            .h4 {
                margin-bottom: 6px;
                line-height: inherit;
                font-size: 16px;
                color: #433a36;
            }
            p {
                font-size: 12px;
                color: #76645c;
            }
        }
    }
    &-material {
        .pro-article-title {
            text-align: center;
        }
        &-box {
            display: flex;
            flex-wrap: wrap;
            padding: 15px 10px;
            border-radius: 4px;
            background-color: #f6f3ed;
            p {
                margin-bottom: 0;
            }
            .item {
                display: flex;
                padding: 15px 20px;
                &-icon {
                    flex: 0 0 56px;
                    img {
                        display: block;
                        width: 100%;
                    }
                }
                &-info {
                    line-height: 1.6;
                    margin: 2px 0 0 12px;
                    .h4 {
                        margin-bottom: 6px;
                        line-height: inherit;
                        font-size: 16px;
                    }
                    &-list {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: -8px;
                        li {
                            display: flex;
                            align-items: center;
                            margin: 0 15px 8px 0;
                            list-style: none !important;
                        }
                        .img {
                            margin-right: 9px;
                            width: 20px;
                            img {
                                display: block;
                                width: 100%;
                            }
                        }
                    }
                    &-list2 {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: -16px;
                        li {
                            margin: 0 20px 16px 0;
                            list-style: none !important;
                        }
                        .img {
                            margin: 0 auto 8px;
                            text-align: center;
                            width: 70px;
                            height: 70px;
                            line-height: 70px;
                            img {
                                display: inline-block;
                                vertical-align: middle;
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    &-design {
        margin-top: px2rem(70px);
        .pro-article-title {
            text-align: center;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -12px -24px;
            li {
                padding: 0 12px 24px;
                width: (100% / 3);
                box-sizing: border-box;
            }
            .img {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: (222 / 335) * 100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .h4 {
                margin-top: 15px;
                line-height: 1.6;
                font-size: 14px;
                color: #76645c;
            }
        }
    }
    &-space {
        margin-top: px2rem(70px);
        .pro-article-title {
            text-align: center;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -18px;
            li {
                padding: 0 18px 36px;
                width: 50%;
                box-sizing: border-box;
            }
            .img {
                position: relative;
                width: 100%;
                height: 0;
                padding-bottom: (300 / 510) * 100%;
                overflow: hidden;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .h4 {
                margin: 12px 0 3px;
                line-height: 1.4;
                font-size: 18px;
                color: #433a36;
            }
            p {
                line-height: 1.6;
                font-size: 14px;
                color: #76645c;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 50px;
        &-title {
            p {
                font-size: 18px;
            }
            h2 {
                font-size: 26px;
            }
        }
        &-concept {
            margin-bottom: px2rem(80px);
            .info {
                padding: 30px 0 0 50px;
            }
        }
        &-series {
            margin-bottom: px2rem(80px);
            &-list {
                margin: px2rem(10px) px2rem(-10px) 0;
                li {
                    padding: px2rem(10px);
                }
                .box {
                    padding-top: 16px;
                    padding-bottom: 16px;
                }
            }
        }
        &-space {
            &-list {
                margin: 0 -15px;
                li {
                    padding: 0 15px 30px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding-left: px2rem(30px);
        padding-right: px2rem(30px);
        &-title {
            margin-bottom: 40px;
            p {
                font-size: 16px;
            }
            h2 {
                font-size: 24px;
            }
        }
        &-concept {
            display: block;
            .info {
                padding-left: 0;
            }
            .img {
                width: 100%;
            }
        }
        &-series {
            display: block;
            .img {
                width: 100%;
            }
            .info {
                margin: 0 0 25px;
            }
        }
        &-material {
            &-box {
                .item {
                    padding: 12px 15px;
                    &-icon {
                        flex: 0 0 50px;
                    }
                    &-info {
                        margin-top: 0;
                        .h4 {
                            margin-bottom: 4px;
                            font-size: 15px;
                        }
                        &-list {
                            .img {
                                margin-right: 6px;
                                width: 18px;
                            }
                        }
                        &-list2 {
                            .img {
                                width: 60px;
                                height: 60px;
                                line-height: 60px;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        padding-top: px2rem(70px);
        padding-bottom: px2rem(50px);
        &-title {
            padding-bottom: 12px;
            margin-bottom: 20px;
            p {
                font-size: 14px;
            }
            h2 {
                font-size: 20px;
            }
        }
        &-concept {
            .info {
                padding-top: 16px;
            }
            .pro-article-title {
                margin-bottom: 10px;
            }
        }
        &-series {
            .info {
                margin-bottom: 15px;
            }
            &-list {
                line-height: 20px;
                .box {
                    padding: px2rem(30px) px2rem(20px);
                }
                .h4 {
                    margin-bottom: 4px;
                    font-size: 15px;
                }
                p {
                    font-size: 11px;
                }
            }
            .pro-article-title {
                margin-bottom: 10px;
            }
        }
        &-material {
            &-box {
                padding: 12px 5px;
                .item {
                    padding: 10px 15px;
                    &-icon {
                        flex: 0 0 45px;
                    }
                    &-info {
                        margin-top: 0;
                        font-size: 12px;
                        .h4 {
                            font-size: 14px;
                        }
                        &-list {
                            li {
                                margin-right: 10px;
                            }
                            .img {
                                margin-right: 4px;
                                width: 16px;
                            }
                        }
                        &-list2 {
                            margin-bottom: -10px;
                            li {
                                margin: 0 15px 10px 0;
                            }
                            .img {
                                margin-bottom: 5px;
                                width: 45px;
                                height: 45px;
                                line-height: 45px;
                            }
                        }
                    }
                }
            }
        }
        &-design {
            &-list {
                display: block;
                margin: 0;
                li {
                    padding: 0 0 20px;
                    width: 100%;
                }
                .h4 {
                    margin-top: 10px;
                }
            }
        }
        &-space {
            &-list {
                margin: 0 px2rem(-15px);
                li {
                    padding: 0 px2rem(15px) px2rem(30px);
                }
                .h4 {
                    margin-top: 10px;
                    font-size: 15px;
                }
                p {
                    font-size: 12px;
                }
            }
        }
    }
}

.case-article {
    @include articleReset(16px, 36px);
    color: #666;
    p {
        margin-bottom: 20px;
    }
    @media screen and (max-width: 1440px) {
        line-height: 32px;
        font-size: 14px;
    }
    @media screen and (max-width: 750px) {
        line-height: 28px;
        font-size: 13px;
        p {
            margin-bottom: 15px;
        }
    }
}

.case-title {
    margin-bottom: 20px;
    p {
        line-height: 1.2;
        margin-bottom: 0;
        font-size: 16px;
        color: #999;
        text-transform: uppercase;
    }
    .title {
        line-height: 1.4;
        font-size: 30px;
        color: #333;
    }
    @media screen and (max-width: 1440px) {
        p {
            font-size: 14px;
        }
        .title {
            font-size: 28px;
        }
    }
    @media screen and (max-width: 1024px) {
        p {
            font-size: 12px;
        }
        .title {
            font-size: 26px;
        }
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 15px;
        p {
            font-size: 11px;
        }
        .title {
            font-size: 22px;
        }
    }
}

.case-introduce {
    padding: px2rem(75px) 0;
    .wp1460 {
        display: flex;
    }
    &-img {
        width: (700 / 1460) * 100%;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .case-title {
        margin-bottom: 15px;
    }
    &-info {
        flex: 1;
        min-width: 0;
        padding: 25px 0 0 45px;
    }
    @media screen and (max-width: 1440px) {
        &-info {
            padding-top: 15px;
        }
    }
    @media screen and (max-width: 1200px) {
        .wp1460 {
            display: block;
        }
        &-img {
            width: 100%;
        }
        &-info {
            padding: 30px 0 0;
        }
    }
    @media screen and (max-width: 750px) {
        &-info {
            padding-top: 20px;
        }
        .case-title {
            margin-bottom: 10px;
        }
    }
}

.case-structure {
    padding: px2rem(70px) 0 px2rem(80px);
    background-color: #fff;
    .case-title {
        margin-bottom: 55px;
        text-align: center;
    }
    &-img {
        text-align: center;
    }
    @media screen and (max-width: 1024px) {
        .case-title {
            margin-bottom: px2rem(40px);
        }
    }
}

.case-project {
    padding: px2rem(70px) 0 px2rem(80px);
    text-align: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .case-title {
        margin-bottom: px2rem(40px);
        .title {
            color: #fff;
        }
    }
}

.case-manage {
    padding: px2rem(70px) 0 px2rem(80px);
    text-align: center;
    background-color: #fff;
    .case-title {
        margin-bottom: 15px;
    }
    .p {
        margin: 0 auto;
        width: 1070px;
        max-width: 100%;
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: 40px -13px 0;
        li {
            padding: 0 13px 26px;
            width: (100% / 6);
            list-style: none !important;
            box-sizing: border-box;
        }
        .box {
            height: 100%;
            padding: 46px 20px 40px;
            background-color: #fefcf7;
            border: 1px solid #dfdfdf;
            box-sizing: border-box;
        }
        .icon {
            height: 45px;
            line-height: 45px;
            img {
                display: inline-block;
                vertical-align: middle;
                width: auto;
                max-height: 100%;
            }
        }
        .title {
            margin: 14px 0 8px;
            line-height: 1.4;
            font-size: 20px;
            color: #262626;
        }
        p {
            margin-bottom: 0;
            line-height: 26px;
            font-size: 15px;
            color: #777;
        }
    }
    @media screen and (max-width: 1440px) {
        .p {
            width: 960px;
        }
        &-list {
            margin: 40px px2rem(-10px) 0;
            li {
                padding: 0 px2rem(10px) px2rem(20px);
            }
            .box {
                padding: 40px 15px 35px;
            }
            .icon {
                height: 40px;
                line-height: 40px;
            }
            .title {
                margin: 14px 0 8px;
                font-size: 18px;
            }
            p {
                line-height: 24px;
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .p {
            width: 960px;
        }
        &-list {
            margin-top: 30px;
            li {
                width: (100% / 3);
            }
            .title {
                font-size: 16px;
            }
            p {
                line-height: 22px;
                font-size: 13px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .case-title {
            margin-bottom: 10px;
        }
        &-list {
            margin-top: 20px;
            li {
                width: 50%;
            }
            .box {
                padding: 24px 10px 22px;
            }
            .icon {
                height: 34px;
                line-height: 34px;
            }
            .title {
                margin: 12px 0 6px;
                font-size: 15px;
            }
            p {
                line-height: 20px;
                font-size: 12px;
            }
        }
    }
}

.advantage-article {
    @include articleReset(16px, 36px);
    color: #333;
    p {
        margin-bottom: 10px;
    }
    @media screen and (max-width: 1440px) {
        line-height: 32px;
        font-size: 14px;
    }
    @media screen and (max-width: 750px) {
        line-height: 28px;
        font-size: 13px;
    }
}

.advantage-title {
    margin-bottom: 40px;
    text-align: center;
    line-height: 1.4;
    font-size: 40px;
    color: #986b43;
    @media screen and (max-width: 1440px) {
        margin-bottom: 35px;
        font-size: 36px;
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 30px;
        font-size: 30px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 26px;
    }
    @media screen and (max-width: 750px) {
        margin-bottom: 16px;
        font-size: 20px;
    }
}

.advantage-background {
    padding: 90px 0 px2rem(85px);
    background-color: #fff;
    &-box {
        display: flex;
        background-color: #f4f4f4;
        .img {
            width: (700 / 1320) * 100%;
            img {
                display: block;
                width: 100%;
            }
        }
        .info {
            flex: 1;
            min-width: 0;
            padding: 45px 100px 30px 70px;
            &-title {
                margin-bottom: 24px;
                padding-bottom: 12px;
                line-height: 1.4;
                border-bottom: 1px dashed #cacaca;
                p {
                    font-size: 22px;
                }
            }
        }
        .h4 {
            font-size: 26px;
            color: #986b43;
        }
        p {
            margin-bottom: 0;
        }
    }
    @media screen and (max-width: 1440px) {
        &-box {
            .info {
                padding: 40px 60px 30px 50px;
                &-title {
                    margin-bottom: 20px;
                    p {
                        font-size: 20px;
                    }
                }
            }
            .h4 {
                font-size: 24px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding-top: px2rem(75px);
        &-box {
            .info {
                padding: 30px 40px 20px;
                &-title {
                    margin-bottom: 15px;
                    p {
                        font-size: 18px;
                    }
                }
            }
            .h4 {
                font-size: 22px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-box {
            display: block;
            .img {
                width: 100%;
            }
            .info {
                padding-bottom: 30px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-box {
            display: block;
            .img {
                width: 100%;
            }
            .info {
                padding: px2rem(40px);
                &-title {
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    p {
                        font-size: 15px;
                    }
                }
            }
            .h4 {
                font-size: 18px;
            }
        }
    }
}


.advantage-history {
    padding: px2rem(75px) 0 90px;
    background: url(../images/bg3.jpg) center center no-repeat;
    background-size: cover;
    .advantage-title {
        color: #fff;
    }
    &-prev,
    &-next {
        position: absolute;
        top: 40px;
        width: 28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        color: #585a58;
        background-color: #fff;
        outline: none;
        z-index: 9;
        cursor: pointer;
        border-radius: 50%;
        transition: all .3s;
        &:hover {
            color: #fff;
            background-color: $mainYellow;
        }
    }
    &-prev {
        left: 0;
    }
    &-next {
        right: 0;
    }
    &-hd {
        position: relative;
        margin-bottom: 25px;
        padding: 0 55px;
        color: #fff;
        &:before {
            content: '';
            position: absolute;
            top: 55px;
            left: 0;
            right: 0;
            height: 1px;
            background: rgba(255, 255, 255, .6);
        }
        .swiper-slide {
            padding: 0 10px;
            text-align: center;
            cursor: pointer;
            box-sizing: border-box;
            &.on {
                .time {
                    color: $mainYellow;
                    &:after {
                        border-color: $mainYellow;
                        background-color: $mainYellow;
                    }
                }
                .title {
                    color: $mainYellow;
                }
            }
        }
        .time {
            position: relative;
            line-height: 30px;
            font-size: 20px;
            font-family: Microsoft YaHei, "微软雅黑";
            .num {
                display: inline-block;
                font-size: 28px;
            }
            &:after {
                content: '';
                display: block;
                margin: 13px auto 0;
                padding: 3px;
                width: 10px;
                height: 10px;
                border: 1px solid #f1f1f1;
                background-color: #f1f1f1;
                background-clip: content-box;
                border-radius: 50%;
                transition: all .3s;
            }
        }
        .title {
            margin-top: 25px;
            line-height: 1.6;
            font-size: 14px;
            color: #efeeee;
            transition: all .3s;
        }
    }
    &-bd {
        background-color: #fff;
    }
    &-list {
        li {
            display: flex;
            align-items: center;
            list-style: none !important;
            &:nth-child(odd) {
                padding: 30px 50px 30px 75px;
                .img {
                    order: 2;
                    margin-left: 70px;
                }
            }
            &:nth-child(even) {
                padding: 30px 75px 30px 50px;
                .img {
                    margin-right: 70px;
                }
            }
            & + li {
                border-top: 1px solid #ececec;
            }
        }
        .img {
            width: (700 / 1195) * 100%;
        }
        .info {
            flex: 1;
            min-width: 0;
        }
        .h4 {
            margin-bottom: 12px;
            line-height: 1.5;
            font-size: 26px;
            color: #986b43;
        }
    }
    @media screen and (max-width: 1440px) {
        &-hd {
            .time {
                font-size: 18px;
                .num {
                    font-size: 26px;
                }
            }
            .title {
                font-size: 13px;
            }
        }
        &-list {
            li {
                &:nth-child(odd) {
                    .img {
                        margin-left: 60px;
                    }
                }
                &:nth-child(even) {
                    .img {
                        margin-right: 60px;
                    }
                }
            }
            .h4 {
                font-size: 24px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: px2rem(80px);
        &-prev,
        &-next {
            top: 38px;
        }
        &-hd {
            &:before {
                top: 52px;
            }
            .time {
                font-size: 16px;
                .num {
                    font-size: 24px;
                }
                &:after {
                    margin-top: 10px;
                }
            }
            .title {
                margin-top: 20px;
            }
        }
        &-list {
            li {
                &:nth-child(odd) {
                    padding-left: 60px;
                    .img {
                        margin-left: 50px;
                    }
                }
                &:nth-child(even) {
                    padding-right: 60px;
                    .img {
                        margin-right: 50px;
                    }
                }
            }
            .h4 {
                font-size: 22px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-prev,
        &-next {
            top: 36px;
        }
        &-hd {
            &:before {
                top: 50px;
            }
            .time {
                font-size: 15px;
                .num {
                    font-size: 22px;
                }
            }
        }
        &-list {
            li {
                display: block;
                &:nth-child(odd) {
                    padding: px2rem(30px);
                    .img {
                        margin-left: 0;
                    }
                }
                &:nth-child(even) {
                    padding: px2rem(30px);
                    .img {
                        margin-right: 0;
                    }
                }
            }
            .img {
                margin-bottom: 20px;
                width: 100%;
            }
            .h4 {
                margin-bottom: 10px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-prev,
        &-next {
            top: 37px;
            width: 22px;
            height: 22px;
            line-height: 22px;
            font-size: 13px;
        }
        &-hd {
            margin-bottom: 16px;
            padding: 0 30px;
            &:before {
                top: 47px;
            }
            .time {
                font-size: 13px;
                .num {
                    font-size: 18px;
                }
                &:after {
                    margin-top: 8px;
                    width: 8px;
                    height: 8px;
                }
            }
            .title {
                margin-top: 15px;
            }
        }
        &-list {
            .img {
                margin-bottom: 15px;
            }
            .h4 {
                margin-bottom: 8px;
                font-size: 18px;
            }
        }
    }
}

.advantage-base {
    padding: px2rem(75px) 0 100px;
    background-color: #fff;
    &-wp {
        display: flex;
    }
    &-list {
        font-size: 0;
        width: (900 / 1320) * 100%;
        margin-bottom: -18px;
        li {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            padding: 0 18px 18px 0;
            list-style: none !important;
            box-sizing: border-box;
        }
        p {
            margin-bottom: 0;
        }
        .box {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: (250 / 430) * 100%;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 24%;
                background: linear-gradient(0deg, #000, rgba(0, 0, 0, .5) 50%, transparent);
                z-index: 2;
            }
        }
        .info {
            position: absolute;
            bottom: 10px;
            left: px2rem(20px);
            right: px2rem(20px);
            line-height: 24px;
            font-size: 14px;
            color: #fff;
            z-index: 6;
            &-title {
                font-weight: bold;
                font-size: 18px;
            }
            p {
                font-weight: bold;
            }
        }
    }
    &-info {
        flex: 1;
        min-width: 0;
        padding: 85px 50px 40px;
        background-color: #f4f4f4;
        .title {
            margin-bottom: 20px;
            line-height: 1.5;
            font-size: 26px;
            color: #333;
        }
    }
    @media screen and (max-width: 1440px) {
        &-list {
            .info {
                line-height: 22px;
                font-size: 13px;
                &-title {
                    font-size: 16px;
                }
            }
        }
        &-info {
            padding: 60px 40px 40px;
            .title {
                margin-bottom: 15px;
                font-size: 24px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: px2rem(85px);
        &-list {
            .info {
                line-height: 20px;
                font-size: 12px;
                &-title {
                    font-size: 15px;
                }
            }
        }
        &-info {
            padding: 40px 30px 30px;
            .title {
                margin-bottom: 12px;
                font-size: 22px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-wp {
            display: block;
        }
        &-list {
            width: auto;
            margin: 0 -15px;
            li {
                padding: 0 15px px2rem(30px);
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-list {
            margin: 0;
            li {
                display: block;
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }
            .info {
                bottom: 8px;
            }
        }
        &-info {
            padding: px2rem(50px) px2rem(40px) px2rem(40px);
            .title {
                margin-bottom: 7px;
                font-size: 20px;
            }
        }
    }
}

.advantage-design {
    padding: px2rem(75px) 0 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .advantage-title {
        color: #fff;
    }
    &-box {
        display: flex;
        align-items: center;
        padding: 15px 0;
        background-color: #fff;
        .info {
            flex: 1;
            min-width: 0;
            padding: 0 75px;
        }
        .img {
            padding: 0 20px;
            width: 50%;
            text-align: center;
        }
    }
    @media screen and (max-width: 1440px) {
        &-box {
            .info {
                padding: 0 60px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: px2rem(85px);
        &-box {
            .info {
                padding: 0 50px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-box {
            display: block;
            padding: px2rem(50px) px2rem(30px) px2rem(20px);
            .info {
                margin-bottom: 20px;
                padding: 0;
                text-align: center;
            }
            .img {
                padding: 0;
                width: 100%;
            }
        }
    }
}

.advantage-media {
    padding: px2rem(75px) 0 100px;
    background-color: #fff;
    &-box {
        padding: 35px 50px 50px;
        text-align: center;
        background-color: #f4f4f4;
        .info {
            margin: 0 auto 12px;
            width: 1076px;
            max-width: 100%;
        }
        .img-list {
            display: flex;
            margin: 0 -8px;
            li {
                padding: 16px 8px 0;
                list-style: none !important;
                box-sizing: border-box;
                &:first-child {
                    width: (558 / 1236) * 100%;
                }
                &:last-child {
                    width: (678 / 1236) * 100%;
                }
                img {
                    width: 100%;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding-bottom: px2rem(85px);
    }
    @media screen and (max-width: 1024px) {
        &-box {
            padding: px2rem(50px) px2rem(30px) px2rem(30px);
            .info {
                margin-bottom: 8px;
            }
            .img-list {
                display: block;
                margin: 0;
                li {
                    padding: px2rem(16px) 0 0;
                    &:first-child {
                        width: 100%;
                    }
                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.advantage-system {
    padding: 110px 0 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .advantage-title {
        margin-bottom: 90px;
        color: #fff;
    }
    &-box {
        display: flex;
        align-items: center;
        padding: 45px 60px 45px 35px;
        background-color: #fff;
        .img {
            margin-right: 50px;
            width: (700 / 1225) * 100%;
            img {
                display: block;
                width: 100%;
            }
        }
        .info {
            flex: 1;
            min-width: 0;
            &-title {
                margin-bottom: 10px;
                padding-bottom: 14px;
                line-height: 1.5;
                font-size: 26px;
                color: #986b43;
                border-bottom: 1px dashed #d0d0d0;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        padding-top: 100px;
        .advantage-title {
            margin-bottom: 80px;
        }
        &-box {
            padding: 40px 50px 40px 30px;
            .info {
                &-title {
                    font-size: 24px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: px2rem(75px) 0 px2rem(85px);
        .advantage-title {
            margin-bottom: px2rem(50px);
        }
        &-box {
            .img {
                margin-right: 40px;
            }
            .info {
                &-title {
                    font-size: 22px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-box {
            display: block;
            padding: px2rem(30px);
            .img {
                width: 100%;
                margin: 0 0 20px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-box {
            .img {
                margin-bottom: 14px;
            }
            .info {
                &-title {
                    margin-bottom: 6px;
                    padding-bottom: 8px;
                    font-size: 18px;
                }
            }
        }
    }
}

.advantage-productivity {
    padding-top: px2rem(75px);
    background-color: #fff;
    &-box1 {
        padding: 40px 0 px2rem(75px);
        background-color: #f4f4f4;
        p {
            margin-bottom: 0;
        }
        .info {
            margin: 0 auto 15px;
            text-align: center;
            width: 1120px;
            max-width: 100%;
        }
        .img-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -12px;
            li {
                padding: 12px;
                list-style: none !important;
                box-sizing: border-box;
            }
            img {
                display: block;
                width: 100%;
            }
            &2 {
                margin-top: -10px;
                li {
                    width: 50%;
                }
            }
            &4 {
                li {
                    width: 25%;
                }
            }
        }
    }
    &-box2 {
        padding: px2rem(75px) 0 100px;
        text-align: center;
        .title {
            margin-bottom: 3px;
            line-height: 1.5;
            font-size: 26px;
            color: #986b43;
            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                margin-right: 6px;
                width: 12px;
                height: 12px;
                background-color: #cb9d73;
                border-radius: 50%;
            }
        }
        .info {
            margin: 0 auto 30px;
            width: 640px;
            max-width: 100%;
        }
        .img {
            padding: 0 px2rem(20px);
        }
    }
    @media screen and (max-width: 1440px) {
        &-box2 {
            .title {
                font-size: 24px;
                &:before {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &-box1 {
            padding-top: 30px;
        }
        &-box2 {
            padding-bottom: px2rem(85px);
            .title {
                font-size: 22px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-box1 {
            padding-top: 20px;
            .info {
                margin-bottom: 10px;
            }
            .img-list {
                margin: 0 px2rem(-15px);
                li {
                    padding: px2rem(15px);
                }
                &2 {
                    margin-top: 0;
                    li {
                        margin-top: px2rem(-15px);
                        width: 100%;
                    }
                }
                &4 {
                    li {
                        width: 50%;
                    }
                }
            }
        }
        &-box2 {
            .title {
                font-size: 20px;
                &:before {
                    width: 8px;
                    height: 8px;
                }
            }
            .info {
                margin-bottom: 20px;
            }
        }
    }
}

.advantage-marketing {
    padding: 80px 0 100px;
    text-align: center;
    color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .advantage-title {
        margin-bottom: 10px;
        color: #fff;
    }
    &-img {
        display: flex;
        margin: 45px -5px 0;
        li {
            padding: 10px 5px 0;
            list-style: none !important;
            box-sizing: border-box;
            &:first-child {
                width: (615 / 1470) * 100%;
            }
            &:last-child {
                width: (855 / 1470) * 100%;
            }
            img {
                display: block;
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: px2rem(75px) 0 px2rem(85px);
        &-img {
            margin: 35px -4px 0;
            li {
                padding: 8px 4px 0;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .advantage-title {
            margin-bottom: 5px;
        }
        &-img {
            margin: 25px -2px 0;
            li {
                padding: 4px 2px 0;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-img {
            display: block;
            margin: 15px 0 0;
            li {
                padding: px2rem(10px) 0 0;
                &:first-child {
                    width: 100%;
                }
                &:last-child {
                    width: 100%;
                }
            }
        }
    }
}

.advantage-support {
    padding: 80px 0 px2rem(70px);
    .advantage-title {
        margin-bottom: 5px;
    }
    .p {
        text-align: center;
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: 25px -12px 0;
        li {
            padding: 24px 12px 0;
            width: (100% / 3);
            list-style: none !important;
            box-sizing: border-box;
            img {
                display: block;
                width: 100%;
            }
        }
    }
    &-bottom {
        display: flex;
        align-items: center;
        margin: 55px auto 0;
        width: 1100px;
        max-width: 100%;
        .img {
            margin-right: 35px;
            width: (512 / 1100) * 100%;
        }
        .info {
            flex: 1;
            min-width: 0;
        }
        .h4 {
            font-size: 22px;
            color: #986b43;
        }
    }
    @media screen and (max-width: 1200px) {
        padding-top: px2rem(75px);
        &-list {
            margin-top: 15px;
        }
        &-bottom {
            margin-top: 45px;
            .h4 {
                font-size: 20px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-bottom {
            margin-top: 35px;
            .img {
                margin-right: 30px;
            }
            .h4 {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-list {
            display: block;
            margin: 10px 0 0;
            li {
                padding: px2rem(30px) 0 0;
                width: 100%;
            }
        }
        &-bottom {
            display: block;
            margin-top: 20px;
            text-align: center;
            .img {
                margin: 0 0 15px;
                width: 100%;
            }
            .h4 {
                font-size: 16px;
            }
        }
    }
}

.advantage-dealer {
    padding: 80px 0 px2rem(85px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .advantage-title {
        color: #fff;
    }
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        li {
            padding: 10px;
            width: 50%;
            box-sizing: border-box;
        }
        .box {
            position: relative;
            display: flex;
            padding: 30px 55px 30px 30px;
            height: 100%;
            background-color: #f4f4f4;
            border: 1px solid #d3c09b;
            overflow: hidden;
            box-sizing: border-box;
        }
        .img {
            width: 200px;
            img {
                display: block;
                width: 100%;
            }
        }
        .tag {
            position: absolute;
            top: 24px;
            right: -42px;
            transform: rotate(50deg);
            width: 150px;
            text-align: center;
            line-height: 30px;
            font-size: 16px;
            color: #000;
            background-color: #d3c09b;
            z-index: 2;
        }
        p {
            margin-bottom: 0;
        }
        .info {
            flex: 1;
            min-width: 0;
            padding: 18px 0 0 30px;
            &-title {
                margin-bottom: 8px;
                line-height: 1.5;
                font-size: 24px;
                color: #986b43;
            }
        }
        .p-brown {
            line-height: 30px;
            color: #986b43;
        }
    }
    @media screen and (max-width: 1440px) {
        &-list {
            .img {
                width: 180px;
            }
            .tag {
                top: 20px;
                right: -34px;
                width: 130px;
                line-height: 28px;
                font-size: 14px;
            }
            .info {
                padding-top: 12px;
                &-title {
                    margin-bottom: 6px;
                    font-size: 22px;
                }
            }
            .p-brown {
                line-height: 28px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding-top: px2rem(75px);
        &-list {
            .box {
                padding: 20px 40px 20px 20px;
            }
            .img {
                width: 150px;
            }
            .info {
                padding: 8px 0 0 20px;
                &-title {
                    margin-bottom: 6px;
                    font-size: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        &-list {
            display: block;
            margin: 0;
            li {
                width: 100%;
                padding: px2rem(15px) 0 0;
            }
            .box {
                padding: px2rem(30px) px2rem(50px) px2rem(30px) px2rem(30px);
            }
        }
    }
    @media screen and (max-width: 750px) {
        &-list {
            .img {
                width: 120px;
            }
            .tag {
                top: 16px;
                right: -28px;
                width: 100px;
                line-height: 22px;
                font-size: 12px;
            }
            .info {
                padding: 0 0 0 px2rem(30px);
                line-height: 24px;
                font-size: 12px;
                &-title {
                    margin-bottom: 4px;
                    font-size: 16px;
                }
            }
            .p-brown {
                line-height: 22px;
            }
        }
    }
}

.join-box {
    padding: 60px 0 px2rem(80px);
    text-align: center;
    background: url(../images/advant29.jpg) center center no-repeat;
    background-size: cover;
    &-wp {
        margin: 0 auto;
        max-width: 100%;
        padding: 0 px2rem(30px);
        width: 1160px;
        box-sizing: border-box;
    }
    .title {
        margin-bottom: 18px;
        line-height: 1.5;
        font-size: 36px;
        color: #986b43;
    }
    p {
        line-height: 30px;
        font-size: 16px;
        color: #666;
    }
    .p-22 {
        font-size: 22px;
    }
    &-form {
        display: flex;
        margin-top: 35px;
        line-height: 46px;
        .input-box {
            display: flex;
            width: 200px;
            margin-right: 15px;
            padding-left: 13px;
            background-color: #fff;
            box-sizing: border-box;
            &:nth-child(3) {
                flex: 1;
                min-width: 0;
            }
        }
        .input-text {
            font-size: 16px;
            color: #666;
        }
        button,
        input {
            height: 46px;
            line-height: 46px;
            border: none;
            outline: none;
        }
        .input {
            flex: 1;
            min-width: 0;
            input {
                display: block;
                width: 100%;
                padding-right: 13px;
                font-size: 16px;
                color: #666;
                box-sizing: border-box;
            }
        }
        button {
            text-align: center;
            width: 160px;
            font-size: 20px;
            color: #fff;
            background-color: #a68b73;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                background-color: $mainYellow;
            }
        }
        .captcha {
            margin-right: 15px;
            width: 260px;
        }
    }
    @media screen and (max-width: 1440px) {
        .title {
            font-size: 32px;
        }
        p {
            line-height: 28px;
            font-size: 14px;
        }
        .p-22 {
            font-size: 20px;
        }
        &-form {
            .input-box {
                width: 180px;
            }
            .input-text {
                font-size: 14px;
            }
            .input {
                input {
                    font-size: 14px;
                }
            }
            button {
                width: 140px;
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .title {
            margin-bottom: 15px;
            font-size: 28px;
        }
        .p-22 {
            font-size: 18px;
        }
        &-form {
            .input-box {
                width: 160px;
            }
            button {
                width: 100px;
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding-top: px2rem(75px);
        .title {
            font-size: 26px;
        }
        .p-22 {
            font-size: 16px;
        }
        &-form {
            flex-wrap: wrap;
            .input-box {
                margin: 0 0 15px;
                width: calc((100% - 15px) / 2);
                &:nth-child(2) {
                    margin-left: 15px;
                }
                &:nth-child(3) {
                    flex: 0 0 100%;
                }
            }
            button {
                margin: 0 auto;
                width: 160px;
            }
            .captcha {
                margin: 0 0 15px;
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 750px) {
        .title {
            margin-bottom: 8px;
            font-size: 22px;
        }
        p {
            line-height: 24px;
            font-size: 13px;
        }
        .p-22 {
            font-size: 15px;
        }
        &-form {
            display: block;
            margin-top: 16px;
            line-height: 40px;
            button,
            input {
                height: 40px;
                line-height: 40px;
            }
            .input-box {
                margin-bottom: px2rem(20px);
                width: 100%;
                &:nth-child(2) {
                    margin-left: 0;
                }
            }
            .captcha {
                margin-bottom: px2rem(20px);
            }
            button {
                width: 100%;
                font-size: 16px;
            }
            .input-text {
                font-size: 13px;
            }
            .input {
                input {
                    font-size: 13px;
                }
            }
        }
    }
}
.query-con {
    background-color: #fff;
}
.query-article {
    border-top: 1px solid #eee;
    padding: px2rem(60px) px2rem(80px);
    font-size: 16px;
    color: #333;
    img {
        max-width: 100%;
        height: auto;
    }
    .item {
        display: flex;
        align-items: center;
        margin-bottom: px2rem(60px);
        &:last-child {
            margin-bottom: 0;
        }
        > img {
            width: 80px;
            height: 80px;
            display: block;
            margin-right: px2rem(30px);
        }
        strong {
            margin-bottom: 5px;
            display: block;
        }
        p {
            line-height: 42px;
        }
    }
    @media (max-width: 800px) {
        padding: px2rem(40px);
    }
    @media (max-width: 750px) {
        .item {
            > img {
                width: 60px;
                height: 60px;
            }
            p {
                font-size: 14px;
            }
        }
    }
}
.query-box {
    padding: px2rem(100px) px2rem(70px) px2rem(70px);
    .cn {
        font-size: 30px;
        color: #333;
        margin-bottom: 5px;
    }
    .en {
        font-size: 16px;
        color: #999;
        margin-bottom: 10px;
    }
    .tip {
        font-size: 18px;
        color: #333;
    }
    @media (max-width: 800px) {
        padding: px2rem(40px);
    }
    @media (max-width: 750px) {
        .cn {
            font-size: 20px;
        }
        .en {
            font-size: 14px;
        }
        .tip {
            font-size: 14px;
        }
    }
}
.query-form {
    display: flex;
    margin-top: px2rem(50px);
    .input {
        flex: 1;
        overflow: hidden;
        margin-right: 20px;
        input {
            width: 100%;
            display: block;
            box-sizing: border-box;
            padding: 0 15px;
            border: 1px solid #bfbfbf;
            line-height: 58px;
            height: 60px;
            font-size: 16px;
            color: #333;
            appearance: none;
        }
    }
    button {
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        background: #3e3e3e;
        cursor: pointer;
        transition: all .2s;
        appearance: none;
        border: none;
        &:hover {
            background: $mainYellow;
        }
    }
    @media (max-width: 750px) {
        display: block;
        .input {
            margin-right: 0;
            margin-bottom: px2rem(20px);
            input {
                padding: 0 10px;
                font-size: 14px;
                line-height: 40px;
                height: 42px;
            }
        }
        button {
            width: 100%;
            height: 42px;
            line-height: 42px;
        }
    }
}
.query-result {
    margin-top: px2rem(40px);
    .t {
        position: relative;
        font-size: 24px;
        color: #333;
        padding-bottom: 10px;
        margin-bottom: px2rem(20px);
        &:before {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 44px;
            height: 2px;
            background: $mainYellow;
            content: '';
            display: block;
        }
        @media (max-width: 750px) {
            font-size: 20px;
        }
    }
}
.query-table {
    .th {
        box-sizing: border-box;
        padding: 0 px2rem(40px);
        line-height: 60px;
        font-size: 18px;
        color: #333;
        background: #f4f3f3;
        border-bottom: 1px solid #fff;
    }
    .row {
        background-color: #f4f3f3;
        display: flex;
        &:nth-child(2n) {
            background-color: #f9f8f8;
        }
        .td {
            flex: 1;
            overflow: hidden;
            box-sizing: border-box;
            padding: 15px px2rem(20px) 15px px2rem(40px);
            font-size: 16px;
            color: #666;
            line-height: 30px;
            border-left: 1px solid #eaeaea;
            border-bottom: 1px solid #fff;
            &:first-child {
                border-left: none;
            }
            span {
                color: #333;
            }
        }
    }
    @media (max-width: 750px) {
        .th {
            font-size: 16px;
        }
        .row {
            display: block;
            .td {
                border-left: none;
                font-size: 14px;
            }
        }
    }
}
.query-no-data {
    padding: px2rem(40px) 0;
    text-align: center;
    font-size: 16px;
    color: #333;
}